@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "BadBlockheadFull";
  src: local("BadBlockheadFull"),
    url("fonts/BadBlockheadFull.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

p {
  margin-bottom: 0px !important;
}

.main {
  padding-top: 89px;
  background: linear-gradient(
    91.93deg,
    #421341 0%,
    #451e69 47.87%,
    #400c42 96.74%
  );
  color: #ffffff;
  width: 100%;
  height: 100%;
}

.py-custom {
  padding-top: 29px !important;
  padding-bottom: 30px !important;
}

.green {
  color: #9eea16 !important;
}

.list-hunter-modal > div > div {
  color: white;
  padding: 21px 28px;
  border-radius: 20px;
  background-color: #2d1c35;
}

.green-active {
  color: #7dfb4b;
}

.orange-active {
  color: #ffa841;
}

.red-active {
  color: #ea1a19;
}

.blue {
  color: #4faeff !important;
}

.purpal {
  color: #c84fff !important;
}

.orange {
  color: #ff8500 !important;
}

.container-x {
  max-width: 1320px;
  margin: auto;
  padding: 0 10px;
}

.token-sale-container {
  justify-content: center;
  padding-bottom: 40px;
}

.fixedheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.headerM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding:10px 0 ;  */
  background: transparent;
  padding: 20px 0;
}

.logo-img {
  width: 210px;
  height: 42px;
}

.left-header {
  display: flex;
  align-items: center;
  color: #ffffff !important;
}

.left-header .market img {
  margin-left: 10px;
  margin-right: 10px;
}

.left-header .market p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}

.left-header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
}

.right-header {
  display: flex;
  align-items: center;
}

.right-header a p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0 10px;
}

.right-header a .selectedp {
  font-weight: 600 !important;
}

.right-header .cart {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 10px;
  position: relative;
}

.right-header .cart-badge {
  position: absolute;
  background: #ff0000;
  border-radius: 16px;
  right: -7px;
  top: -7px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idnumber {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 0 0 10px;
}

.right-header .menu {
  align-items: center;
  padding: 15px;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 10px;
}

.bg-header {
  background-color: rgba(0, 0, 0, 0.7);
}

.fixedTop {
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.game-container {
  border-radius: 5%;
  box-shadow: 0px 1px 15px #000000;
}

.main-video-container {
  border-radius: 3%;
}

.mobile-nav-links p {
  padding: 10px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0 10px;
}

.mobile-nav-links :hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 9px;
}

.mobile-nav-content {
  left: 0;
  right: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 999;
}

.p-43 {
  padding: 43px !important;
  border-radius: 0 0 9px 9px;
}

.welcome-video-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap-reverse;
  width: 80%;
}

@media screen and (max-width: 600px) {
  .welcome-video-section {
    width: 100%;
  }
}

.ticketimg {
  height: 100%;
  /* height: calc(100vw/6) !important; */
}

.hero-banner {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: relative;
}

.hero-banner img {
  width: 100%;
}

.sortbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(45, 28, 53, 0.8);
  border-radius: 9px 9px 0px 0px;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
}

.active-summoningbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(45, 28, 53, 0.8);
  border-radius: 9px 9px 0px 0px;
}

.display-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(45, 28, 53, 0.8);
  border-radius: 9px;
}

.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}

.video-container video {
  position: absolute;
  top: 97px;
  left: 0;
  width: 100%;
  height: 94%;
  object-fit: cover;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}

.summoned-hunter-container {
  background: #301243;
  border-radius: 9px;
  padding-bottom: 15px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  padding: 30px;
}

.sh-container {
  background: #310a3d;
  border-radius: 9px;
  margin: 1rem 29rem;
}

.summoned-hunter-text {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1%;
  color: #ffffff;
}

.summoned-hunter-subtext {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  line-height: 37.5px;
  letter-spacing: 1%;
  color: #ffffff;
}

.px-5rem {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

/* .min-h-x {
  min-height: 170px;
} */

.sort-dropdown {
  display: flex;
  align-items: center;
}

.sort-dropdown .Drop-down {
  margin: 0 20px;
}

.powerbar img {
  width: 38px;
  /* width: 45px; */
}

.dropdown-menu {
  background: #413049 !important;
  z-index: 999;
  border-radius: 9px;
  width: 255px;
}

.dropdown-menu button {
  color: #ffffff;
}

.dropdown {
  margin: 0 0 0 10px;
}

.dropdown-menu a {
  padding: 10px;
}

.modal-content img,
.cart img,
.menu img,
.dropdown-menu img,
.sort-mobile-menu img,
.Sidebar-Mobile img,
.single-power-badge img,
.Artifacts-info img,
.table-card img,
.image-copy,
.connected-modal img {
  display: initial;
  width: auto;
  object-fit: inherit;
}

.Artifacts-info img {
  width: 66px;
  height: 66px;
  border-radius: 9px;
}

.dropdown-item {
  width: auto !important;
  margin: 5px 10px;
}

.dropdown-menu :hover,
.dropdown-menu :active,
.dropdown-menu :focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  width: 92% !important;
}

.ActiveSelect {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 92% !important;
}

.sortbar h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  margin: 0;
}

.active-summoningbar h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
}

.border-b {
  width: 1650px;
  background: 1px linear-gradient(90deg, #952af4 0%, #f3a157 100%);
}

.drop {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 1px;
  border-radius: 9px !important;
}

.drop-btn {
  background: #413049 !important;
  border-radius: 9px !important;
  padding: 6px 12px;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.search-bar {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
}

.search-bar input {
  color: #ffffff;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  outline: none;
  appearance: none;
  border: none;
  width: 80%;
  padding: 15px 10px 15px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

::placeholder {
  color: #ffffff;
}

.search-icon {
  top: 18px;
  right: 18px;
}

.col-left {
  width: 20% !important;
}

.col-left img {
  width: auto;
  display: initial;
  height: auto;
}

.col-right {
  width: 80% !important;
}

.btn-primary-colapse {
  margin: 23px 0 0 0;
  padding: 15px 20px;
  background: #2d1c35 !important;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2) !important;
}

.btn-secondary-colapse {
  color: white !important;
  margin: 23px 0 0 0 !important;
  padding: 15px 20px !important;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 25px;
  line-height: 37.5px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Poppins";
}

.btn-transparent {
  color: white !important;
  margin: 23px 0 0 0 !important;
  padding: 15px 20px !important;
  background: transparent;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 25px;
  line-height: 37.5px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Poppins";
}

.btn-secondary-disabled {
  color: white !important;
  margin: 23px 0 0 0 !important;
  padding: 15px 20px !important;
  background: grey;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 25px;
  line-height: 37.5px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Poppins";
}

.btn-disabled {
  background: #413049 !important;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins";
  width: 100%;
  color: white !important;
  cursor: not-allowed;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.btn-claimed {
  width: 100%;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins";
  color: white !important;
}

.btn-finished {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.55);
  border-radius: 9px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins";
  color: white !important;
}

.card-collapse.card {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

.colapse-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.border-radiusB {
  border-radius: 10px 10px 10px 10px !important;
}

.border-radiusA {
  border-radius: 10px 10px 0px 0px !important;
}

.cardbody-content.card-body {
  padding: 20px;
  border-image: linear-gradient(90deg, #d1903c 0%, #7321c1 100%) 1;
  border-top: 1px solid;
}

.infocard {
  background: #300a3c !important;
  border-radius: 10px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.table-card {
  background: #331346;
  border-radius: 9px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.table-card td img {
  margin-left: 10px;
}

.mataid,
.namecard {
  padding: 20px 30px !important;
}

.image-copy {
  cursor: pointer;
}

.owner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.cardbody-content .row-1 p {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}

.single-power-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px;
  padding: 1px;
  margin-top: 8px;
}

.single-power-badge {
  position: relative;
  padding: 2px 40px;
  background: #36154a;
  /* border: 1px solid; */
  /* border-image: linear-gradient(90deg, #FBAE3C 0%, #8F13FF 100%) 1; */
  border-radius: 9px;
}

.single-power-badge img {
  position: absolute;
  top: -12px;
  width: 45px;
}

.singlecard-header.singlecard-header {
  background: #310a3d;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding: 20px 30px !important;
  border-radius: 9px 9px 0 0;
}

.cardbody-content .row-6 p {
  margin-left: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}

.cardbody-content .row-1 Label {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}

.badge-bid {
  border: 1px solid #4b3a53;
  border-radius: 9px;
  padding: 8px 20px;
}

.bid-price {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  line-height: 37.5px;
  margin-left: 5px;
}

.bid-price-2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 37.5px;
  margin-left: 5px;
}

@media screen and (min-width: 1024px) and (max-width: 1372px) {
  .badge-bid {
    padding: 8px;
  }

  .badge-bid img {
    margin-right: 5px;
  }

  .cardbody-content .row-1 Label {
    margin-left: 5px;
    font-size: 14px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1199px) {
  .Artifacts-info img {
    width: 33px;
    height: 33px;
  }
  .Artifacts-info p {
    font-size: 14px !important;
  }
  .btn-android-d {
    top: 53% !important;
    width: 26% !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .col-left {
    width: 25% !important;
  }

  .col-right {
    width: 75% !important;
  }

  .cardbody-content .row-1 p {
    font-size: 16px;
  }
}

.Filterbtn {
  display: none !important;
}

.Sidebar-Mobile {
  display: none;
}

.gamedisabled {
  color: #c6cacf;
}

.modal-dialog.Sidebar-Mobile {
  max-width: 100vw;
  background: #2d1c35;
  margin: 0;
  color: #ffff;
  border-radius: 30px 30px 9px 9px;
  top: 52%;
}

.filterheader {
  border-radius: 30px 30px 0 0;
  padding: 15px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
}

.filterheader h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.modal-body.filterbody {
  /* margin-bottom: 118px; */
  max-height: 100%;
  overflow-y: scroll;
}

.modal-footer.filterfooter {
  display: flex;
  background: #37263f;
  border-radius: 9px;
  border-top: 0;
  padding: 30px 20px;
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
}

.resetfilter-btn-grad {
  width: 45%;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 10px 35px;
}

.reset-btn.btn {
  border-radius: 9px;
  background: #413049 !important;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.Applyfilter-btn-grad {
  width: 45%;
}

.Applysort-btn-grad {
  width: 100%;
}

.sort-mobile-menu a {
  padding: 5px 12px;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

.Apply-btn.btn {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.connect-btn-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 10px 15px;
}

.connect-btn.btn {
  background: #413049;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artifacts-name-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 97px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #ffffff;
}

.stat-card-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stat-w-min {
  min-width: 75px;
}

.stats-w-min {
  min-width: 20px;
}

@media screen and (min-width: 912px) and (max-width: 1372px) {
  .modal-dialog.Sidebar-Mobile {
    top: 52%;
  }
}

@media screen and (max-width: 280px) {
  .Artifacts-content {
    justify-content: center !important;
  }

  .sortbar {
    display: block !important;
  }

  .active-summoningbar {
    display: block !important;
  }

  .single-heroimg {
    width: 200px !important;
    height: 200px !important;
  }

  .modal-dialog.Sidebar-Mobile {
    display: flex;
    background: #341d3f;
  }

  .search-bar {
    display: none;
  }

  .sort-dropdown {
    display: none;
  }

  .Filterbtn {
    display: flex !important;
    align-items: center;
  }

  .filterbtn-margin {
    margin-left: 10px !important;
  }

  .col-left {
    display: none;
    width: 100% !important;
  }

  .col-right {
    width: 100% !important;
  }

  .cardbody-content .row-1 p {
    font-size: 16px;
  }

  .heroimg {
    height: 230px !important;
  }

  .cardbody-content.card-body {
    border: none;
    background: #341d3f;
  }

  .card-collapse.card {
    background: #341d3f;
    box-shadow: none;
  }

  .btn-primary-colapse {
    background: none !important;
    box-shadow: none !important;
  }

  .grad-border-bottom {
    border: 1px solid;
    border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
    margin: 10px 0;
  }

  .btn-primary-colapse {
    padding: 15px 0;
  }

  .cardbg-UNCOMMON {
    padding: 50px 10px !important;
  }

  .heroimg {
    height: 180px !important;
  }

  .golden-logo {
    width: 50px;
  }

  .powerbar img {
    width: 30px;
  }

  .name-logo {
    width: 80px;
  }

  .name-card .name {
    font-size: 18px !important;
  }

  .card-nftId {
    font-size: 18px !important;
  }

  .name-card .rarity {
    font-size: 16px !important;
  }

  .name-card .level {
    font-size: 16px !important;
  }

  .stat-card-detail {
    display: block;
  }

  .single-stat {
    margin-top: 10px !important;
  }

  .single-stat img {
    width: auto;
    object-fit: inherit;
  }

  .price-card .time {
    font-size: 16px !important;
  }

  .seemore {
    padding: 8px 60px !important;
    font-size: 16px !important;
    margin: 10px 0 100px 0 !important;
  }
}

.name-card {
  /* padding: 10px; */
  display: flex;
  flex-wrap: nowrap !important;
}

.price-card {
  padding: 10px;
}

.price-card img {
  width: auto;
  object-fit: inherit;
}

.stat-card-item {
  margin-top: 10px;
}

.side-stat {
  max-width: 80px;
}

.Artifacts-info {
  text-align: center;
}

.Artifacts-info p {
  margin-top: 10px;
}

.btn-close-modal {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #ffffff;
  border: 0;
}

.single-stat {
  margin-top: 10px !important;
}

.single-stat img {
  width: auto;
  object-fit: inherit;
}

@media screen and (max-width: 430px) {
  .remove-title {
    font-size: 24px !important;
  }
  .remove-subtitle {
    font-size: 16px !important;
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }
  .input-cost {
    margin-left: 78px !important;
  }
  .btns-revenue {
    padding: 10px !important;
  }
  .input-revenue {
    margin-left: 46px !important;
  }
  .rental-period-label {
    margin-left: 2px !important;
  }
  .name {
    font-size: 18px !important;
  }
  .rent-modal-title {
    font-size: 24px !important;
  }
  .rent-modal-subtitles {
    font-size: 19px !important;
  }
}

@media screen and (max-width: 650px) {
  /* .Artifacts-content{
       display: block !important;
    } */
  .Artifacts-content .Artifacts-info {
    margin-top: 10px;
  }

  .stat-card-detail {
    display: block;
  }

  .name-card {
    padding: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }

  .ownerid {
    max-width: 200px !important;
  }

  .single-price-card {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-right-radius: 9px !important;
    border-top-left-radius: 9px !important;
  }

  .second-price-card {
    position: fixed;
    z-index: 1000;
    bottom: 109px;
    left: 0;
    right: 0;
    border-radius: 9px !important;
  }

  .min-h-x {
    min-height: 170px;
  }

  .single-stat {
    margin-top: 10px !important;
  }

  .single-stat img {
    width: auto;
    object-fit: inherit;
  }
}

@media screen and (min-width: 281px) and (max-width: 983px) {
  .single-heroimg {
    width: 250px !important;
    height: 250px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1340px) {
  .name-card {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .stat-direction {
    display: flex;
    flex-direction: column;
  }

  .artifacts-name-box {
    min-height: 138px;
  }
}

@media screen and (max-width: 983px) {
  .defi-footer img {
    width: 40px !important;
  }

  .single-stat {
    margin-top: 10px !important;
  }

  .single-stat img {
    width: auto;
    object-fit: inherit;
  }

  .single-productcard {
    width: auto !important;
    height: auto !important;
  }

  .singleproductcard.card {
    width: auto !important;
  }

  .singleproductcard-short.card {
    width: auto !important;
  }

  .name-card .name {
    font-size: 22px !important;
  }

  .name-card .rarity {
    font-size: 18px;
  }

  .name-card .level {
    font-size: 18px;
  }

  .owner {
    font-size: 16px;
  }

  .ownerid {
    font-size: 16px !important;
    max-width: 220px;
  }

  .singlecard-header.singlecard-header {
    font-size: 16px;
  }

  .margin-bott {
    margin-bottom: 200px;
  }

  .priceinfo-mobile {
    position: fixed !important;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 !important;
  }

  .assetinfocard.card {
    width: 100% !important;
  }
  .sh-container {
    margin: 0 !important;
  }
}

@media screen and (min-width: 984px) and (max-width: 1250px) {
  .single-productcard {
    width: 380px !important;
    height: 380px !important;
  }

  .singleproductcard.card {
    width: 380px !important;
  }

  .singleproductcard-short.card {
    width: 380px !important;
  }

  .single-heroimg {
    width: 300px !important;
    height: 300px !important;
  }

  .name-card .name {
    font-size: 22px !important;
  }

  .name-card .rarity {
    font-size: 18px;
  }

  .name-card .level {
    font-size: 18px;
  }

  .owner {
    font-size: 16px;
  }

  .ownerid {
    font-size: 16px !important;
  }

  .singlecard-header.singlecard-header {
    font-size: 16px;
  }

  .assetinfocard.card {
    width: 400px !important;
  }

  .sh-container {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .powerbar img {
    width: 45px;
  }

  .powerbar {
    right: 15px !important;
  }
}

@media screen and (min-width: 388px) and (max-width: 1135px) {
  .tier-slider {
    width: 60% !important;
    padding: 10px !important;
  }

  .Artifacts-info p {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 388px) {
  .defi-dash-bg {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .BGEM-price {
    width: auto !important;
  }

  .defi-icons-margin {
    display: block !important;
  }

  .defi-icons-margin img {
    margin-top: 10px !important;
    width: auto;
  }

  .defi-sale-private-flex {
    display: block !important;
  }

  .tier-slider {
    width: 100% !important;
    padding: 10px !important;
  }

  .assattier {
    display: block !important;
  }

  .tier-slider h3 {
    font-size: 22px !important;
  }

  .tiercard {
    margin-bottom: 0 !important;
  }

  .tier-slider {
    width: 100% !important;
    padding: 10px !important;
  }

  .assattier {
    border-radius: 16px !important;
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .tier-info h3 {
    font-size: 22px !important;
  }

  .tier-header {
    font-size: 12px !important;
    padding: 10px 5px !important;
  }

  .tier-info p {
    display: none;
    font-size: 12px !important;
  }

  .tier-slider h3 {
    display: none;
  }

  .tier-info {
    padding: 5px 5px !important;
  }

  .min-bar-value {
    display: none;
    font-size: 14px;
  }

  .max-bar-value {
    display: none;
    font-size: 14px;
  }

  .tier-slider {
    display: flex;
    align-items: center;
  }

  .middle-bar-value {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 281px) and (max-width: 1135px) {
  .tier-slider h3 {
    font-size: 22px !important;
  }

  .tiercard {
    margin-bottom: 0 !important;
  }

  /* .tier-slider {
      width: 50% !important;
      padding: 10px !important;
  } */

  .assattier {
    border-radius: 16px !important;
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .tier-info h3 {
    font-size: 22px !important;
  }

  .tier-header {
    font-size: 12px !important;
    padding: 10px 5px !important;
  }

  .tier-info p {
    display: none;
    font-size: 12px !important;
  }

  .tier-slider h3 {
    display: none;
  }

  .tier-info {
    padding: 5px 5px !important;
  }

  .min-bar-value {
    display: none;
    font-size: 14px;
  }

  .max-bar-value {
    display: none;
    font-size: 14px;
  }

  .tier-slider {
    display: flex;
    align-items: center;
  }

  .middle-bar-value {
    font-size: 18px !important;
  }

  .modal-dialog.Sidebar-Mobile {
    display: flex;
    background: #341d3f;
  }

  .search-bar {
    display: none;
  }

  .sort-dropdown {
    display: none;
  }

  .Filterbtn {
    display: flex !important;
    margin-left: 10px;
    align-items: center;
  }
  .col-left {
    display: none;
    width: 100% !important;
  }

  .col-right {
    width: 100% !important;
  }

  .cardbody-content .row-1 p {
    font-size: 16px;
  }

  .heroimg {
    height: 230px !important;
  }

  .golden-logo {
    width: 75px;
  }

  .ticketimg {
    height: 245px !important;
  }

  .cardbody-content.card-body {
    border: none;
    background: #341d3f;
  }

  .card-collapse.card {
    background: #341d3f;
    box-shadow: none;
  }

  .btn-primary-colapse {
    background: none !important;
    box-shadow: none !important;
  }

  .grad-border-bottom {
    border: 1px solid;
    border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
    margin: 10px 0;
  }

  .btn-primary-colapse {
    padding: 15px 0;
  }

  /* .modal.fade:not(.in).bottom .modal-dialog {
      -webkit-transform: translate3d(0, 125%, 0);
      transform: translate3d(0, 125%, 0);
  } */
}

.badge-bid img {
  margin-right: 10px;
}

/* custom rc-slider  */
.rc-slider-rail {
  background: #4b3a53 !important;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%) !important;
  position: absolute;
  width: 100%;
  height: 4px;
}

.rc-slider-track {
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.rc-slider-handle {
  background: linear-gradient(
    90deg,
    #faa366 38.52%,
    #be57e5 108.15%
  ) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  width: 20.67px !important;
  height: 21px !important;
  margin-top: -8px !important;
}

.rc-slider-dot {
  width: 3px !important;
  height: 4px !important;
  background: linear-gradient(90deg, #faa366 21%, #be57e5 115%) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  border-radius: 0 !important;
  bottom: 0 !important;
}

.rc-slider-dot-active {
  background: #290f2d !important;
}

/* product card */

.productcard.card {
  border-radius: 9px;
  background: #2d1c35;
  border: none;
  margin-bottom: 30px;
  min-width: 320px;
}

@media screen and (max-width: 1135px) {
  .productcard.card {
    min-width: 220px;
  }
}

.card-body-golden {
  background: #2d1c35 !important;
}

.goldencard.card {
  border-radius: 9px !important;
  background: linear-gradient(
    89.95deg,
    #8f13ff 0.01%,
    #f6a552 99.93%
  ) !important;
  border: none !important;
  margin-bottom: 30px;
}

.singleproductcard.card {
  min-width: 400px;
  min-height: 400px;
  max-width: 564px;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 30px;
}

.singleproductcard-short.card {
  min-width: 400px;
  max-width: 564px;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 30px;
}

.assetinfocard.card {
  background: #310a3d;
  border: none;
  width: 564px;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 30px;
}

.heroimg {
  height: 275px;
  /* height: calc(100vw/6) ; */
  width: 100%;
  object-fit: contain;
}

.herovideo {
  border-radius: 9px 9px 0 0;
}

.equipmentimg {
  width: 100%;
  object-fit: contain;
}

.single-productcard {
  width: 564px;
  height: 687px;
}

.single-heroimg {
  width: 437px;
  height: 452px;
}

.cardbg-UNCOMMON {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
  padding: 40px 50px 40px 29px;
}

.cardbg-video {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
}

.cardbg-equiment {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
}

.cardbg-DEMO {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
  padding: 40px 50px 40px 29px;
}

.cardbg-single {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 45px 50px 45px 29px;
}

.Artifacts-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  min-width: 115px;
}

.Asset-info {
  margin-bottom: 1px;
}

.Asset-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.buy-btn-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
  width: 50%;
  margin: 30px 24px 10px 0;
}

.buy-btn.btn {
  background: #36154a !important;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  width: 100%;
}

.bid-btn-grad {
  width: 50%;
}

.bid-btn.btn {
  width: 100%;
  margin: 30px 0 10px 0;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
}

/* .transaction-table .t-header{
    border-radius: 9px 9px 0px 0px;
    background: #310A3D;
    box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2); 
  } */
.transaction-table thead tr th {
  background: #310a3d;
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.transaction-table tbody tr:nth-child(odd) {
  background: #331346;
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.transaction-table tbody tr {
  border-bottom: 0;
}

/* #root > div > div:nth-child(2) > div > div:nth-child(2) > div.table-card > div > table > tbody > tr:nth-child(3) > td:nth-child(1) */
.transaction-table tbody tr:nth-child(n) td:first-child {
  border-radius: 0 0 0 9px !important;
}

.transaction-table tbody tr:nth-child(n) td:last-child {
  border-radius: 0 0 9px 0 !important;
}

.transaction-table tbody tr:nth-child(even) {
  background-color: #36154a;
}

.transaction-table thead tr th:first-child {
  border-radius: 9px 0 0 0;
}

.transaction-table tbody tr td {
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.transaction-table thead tr th:last-child {
  border-radius: 0 9px 0 0;
}

.bg-golden {
  background-image: url("http://localhost:3000/glow.svg");
  /* background: linear-gradient(89.95deg, #8F13FF 0.01%, #F6A552 99.93%); */
  mix-blend-mode: normal;
  border-radius: 9px 9px 0px 0px;
  position: relative;
  box-sizing: border-box;
  padding: 0px 50px 0px 29px;
}

.bg-common {
  background: linear-gradient(180deg, #c6cacf 0%, #586574 100%);
}

.text-common {
  color: #f6f0eb;
}

.bg-uncommon {
  background: linear-gradient(180deg, #5ea056 0%, #1d342c 100%);
}

.text-uncommon {
  color: #7dfb4b;
}

.bg-rare {
  background: linear-gradient(180deg, #4176b8 0%, #272845 100%);
}

.text-rare {
  color: #4faeff;
}

.bg-epic {
  background: linear-gradient(180deg, #8d1bbf 0%, #270a33 100%);
}

.text-epic {
  color: #c84fff;
}

.bg-legendary {
  background: linear-gradient(180deg, #dd8b40 0%, #47270a 100%);
}

.text-legendary {
  color: #ff8500;
}

.cardbg-UNCOMMON .name-logo,
.cardbg-video .name-logo,
.cardbg-single .name-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 50;
  width: auto;
  display: initial;
  height: auto;
}

.cardbg-UNCOMMON .locked-icon,
.cardbg-video .locked-icon,
.cardbg-single .locked-icon {
  position: absolute;
  top: 50% - 60px;
  z-index: 150;
  width: auto;
  display: initial;
  height: auto;
}

.golden-logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}

.cardbg-UNCOMMON .energy-bage,
.cardbg-video .energy-bage,
.cardbg-single .energy-bage {
  position: absolute;
  height: 30px;
  bottom: 20px;
  left: 20px;
  background: #37263f;
  border-radius: 9px;
  z-index: 50;
  display: flex;
  align-items: center;
}

.cardbg-UNCOMMON .energy-bage img,
.cardbg-video .energy-bage img,
.cardbg-single .energy-bage img {
  margin: -2px 10px 0 10px;
  width: 23px;
  height: 39px;
}

.cardbg-UNCOMMON .summoned-energy-bage,
.cardbg-video .summoned-energy-bage,
.cardbg-single .summoned-energy-bage {
  position: absolute;
  height: 30px;
  bottom: 20px;
  left: 20px;
  background: #37263f;
  border-radius: 9px;
  z-index: 50;
  display: flex;
  align-items: center;
}

.cardbg-UNCOMMON .summoned-energy-bage img,
.cardbg-video .summoned-energy-bage img,
.cardbg-single .summoned-energy-bage img {
  margin: -2px 5px 0 10px;
  width: 23px;
  height: 39px;
}

.cardbg-UNCOMMON .summoned-energy-bage span,
.cardbg-video .summoned-energy-bage span,
.cardbg-single .summoned-energy-bage span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-right: 10px;
}

.cardbg-UNCOMMON .energy-bage span,
.cardbg-video .energy-bage span,
.cardbg-single .energy-bage span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-right: 10px;
}

.cardbg-UNCOMMON .listed-bage,
.cardbg-video .listed-bage,
.cardbg-single .listed-bage {
  position: absolute;
  height: 42px;
  bottom: 20px;
  right: 20px;
  background: #37263f;
  border-radius: 9px;
  z-index: 50;
  display: flex;
  align-items: center;
}

.cardbg-UNCOMMON .listed-bage span,
.cardbg-video .listed-bage span,
.cardbg-single .listed-bage span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.01em;
  width: 100%;
  text-align: center;
  padding: 0 10px;
}

.cardbg-UNCOMMON .lent-bage,
.cardbg-video .lent-bage,
.cardbg-single .lent-bage {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #37263f;
  border-radius: 9px;
  z-index: 50;
  display: flex;
  align-items: center;
}

.cardbg-UNCOMMON .lent-bage span,
.cardbg-video .lent-bage span,
.cardbg-single .lent-bage span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.01em;
  width: 100%;
  text-align: center;
  padding: 0 10px;
}

.rented {
  background-color: #413049 !important;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-top: 5px;
}

.rented-out {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.days-left {
  padding: 5px 10px !important;
}

.name-card .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  /* font-size: 25px; */
  line-height: 38px;
}

.name-card .rarity {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}

.name-card .level {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
}

.nftId {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  line-height: 37.5px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.status {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1%;
  color: rgba(255, 255, 255, 0.8);
}

.rent-subtitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  line-height: 37.5px;
  letter-spacing: 1%;
  color: #ffffff;
  margin-top: 2px;
}

.rent-subtitle-2 {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: 1%;
  color: #ffffff;
}

.stats-card {
  background: #37263f;
  border-bottom: 1px solid !important;
  border-image: linear-gradient(90deg, #d1903c 0%, #7321c1 100%) 1 !important;
  border-top: 1px solid !important;
}

.statsbadge-info {
  margin-left: 10px;
  min-width: 75px;
}

.statsbadge-info-b {
  margin: 0 10px;
}

.Artifacts-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.ownerid {
  margin-right: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.06em;
}

.single-stats-card {
  background: #2e1140;
  border-radius: 0 0 9px 9px;
  /* padding: 20px 30px !important; */
}

.single-price-card {
  background: #2e1140;
  border-radius: 9px;
  padding: 20px 30px !important;
}

.second-price-card {
  background: #2e1140;
  border-radius: 9px;
  padding: 20px 30px !important;
}

.b-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.statsbadge-num {
  font-weight: 600;
}

.stats-card .statsbadge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;

  background: #2d1c35;
  border-radius: 0 5px 5px 0;
  padding: 3px 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
}

.stats-card .statsbadge:hover {
  overflow: visible;
  white-space: normal;
  height: auto; /* just added this line */
}

.price-card .offer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
}

.price-card .bid {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
}

.price-card .time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 30px;
  letter-spacing: 0.01em;
  margin: 0 0 0 10px;
}

.powerbar {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 50;
}

.seemore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 103px 8px 100px;
  width: 323px;
  height: 60px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 50px 0 100px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  cursor: pointer;
}

.assattier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #781e87 0%, #69229c 100%);
  border-radius: 20px;
  width: 100%;
  padding: 30px;
  margin: 20px 0;
}

.tiercard {
  background: #300a3c !important;
  border-radius: 9px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.tier-header.card-header {
  text-align: center;
  background: #36104e;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 9px 9px 0px 0px;
}

/* {
    text-align: center;
    background: #36104E;
    box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-transform: uppercase;
    padding: 10px 30px !important;
    border-radius: 9px 9px 0px 0px;
  } */
.tier-card {
  background: #451463;
  border-radius: 0 0 9px 9px;
  padding: 5px !important;
}

.tier-info {
  padding: 5px 10px;
}

.tier-info h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
}

.tier-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  width: 100%;
}

.tier-info p span {
  font-weight: 700;
}

.tier-slider {
  width: 70%;
  padding: 15px;
}

.tier-slider h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 47px;
  text-transform: uppercase;
  text-align: center;
  margin: 0px 0 25px 0;
}

.progress-bar__ {
  width: 100%;
  position: relative;
}

.progress {
  height: auto !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(91.93deg, #421341 0%, #451e69 47.87%, #400c42 96.74%);
  border-radius: 8px;
}

.assetsprgress .progress-bar {
  /* width: 100%; */
  margin: 3px !important;
  background: linear-gradient(90.08deg, #f6a552 0.13%, #8f13ff 261.69%);
  border-radius: 6px;
  height: 28px;
}

.vesting-progress .progress-bar {
  /* width: 100%; */
  background: linear-gradient(90.08deg, #f6a552 0.13%, #8f13ff 261.69%);
  border-radius: 6px;
  height: 8px;
}

.min-bar-value {
  position: absolute;
  top: -5px;
  left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 47px;
}

.middle-bar-value {
  position: absolute;
  top: -5px;
  left: 50%;
  right: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 47px;
}

.max-bar-value {
  position: absolute;
  top: -5px;
  right: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 47px;
  text-align: right;
}

/* mui slider custom */

.MuiSlider-track {
  height: 6px;
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border: none !important;
}

.MuiSlider-thumb {
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
}

.MuiSlider-thumb :hover {
  box-shadow: 0px 0px 0px 8px rgba(218, 233, 248, 0.15);
}

.MuiSlider-rail {
  background: #4b3a53 !important;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%) !important;
}

.MuiSlider-mark {
  background: linear-gradient(90deg, #faa366 21%, #be57e5 115%) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
}

.MuiSlider-markActive {
  background: #290f2d !important;
}

/* modal style */
.close-btn {
  padding: 20px 20px 0 0;
  cursor: pointer;
  margin: 15px 0 0 0;
}

.connected-modal {
  padding: 18px 33px;
  width: 368px !important;
  background: #2d1c35 !important;
  border-radius: 9px !important;
}

.connected-header h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}

.connected-header img {
  cursor: pointer;
}

.success-modal {
  width: 100%;
  background: #2d1c35;
  border-radius: 20px;
}

.success-border {
  background: linear-gradient(
    90.07deg,
    rgba(132, 34, 228, 0.3) 1.1%,
    rgba(251, 174, 60, 0.3) 99.95%
  );
  padding: 1px;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 20px;
}

.center-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  color: var(--bs-modal-color);
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  flex-direction: initial;
}

.connect-modal {
  width: 368px !important;
  background: #2d1c35 !important;
  border-radius: 9px !important;
  animation: animatebottom 0.4s !important;
}

.connect-modal-link-wallet {
  width: 368px !important;
  background: #2d1c35 !important;
  border-radius: 20px !important;
  animation: animatebottom 0.4s !important;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 100%) !important;
  -ms-transform: translate(0, 100%) !important;
  -o-transform: translate(0, 100%) !important;
  transform: translate(0, 1px) !important;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.connect-text,
.success-text {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.sucess-btn-grad {
  width: 100%;
  padding: 10px 20px;
}

.cancle-btn-grad {
  width: 100%;
  padding: 1px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(
      90.06deg,
      rgba(132, 34, 228, 0.3) 1.08%,
      rgba(251, 174, 60, 0.3) 99.95%
    );
  border-radius: 9px !important;
}

.sucess-btn.btn {
  width: 100%;
  padding: 18px 0;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
}

.cancle-btn.btn {
  width: 100%;
  padding: 18px 0;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: #413049;
}

.connect-text h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}

.success-text h3 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 64px;
}

.connect-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.success-text p {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
}

.connect-btn-grad {
  background: #2c1c34;
  border-radius: 9px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 25px 31px;
}

.save-button {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  border-radius: 9px;
  border-style: none;
  color: white;
  text-align: center;
  padding: 7px;
  margin: 20px 65px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.disconnect-btn-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
}

.connect-btn.btn {
  background: #413049;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25.06 px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disconnect-btn-font {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25.06px !important;
  letter-spacing: 0.01em !important;
}

.add-wallet-font {
  font-family: Poppins;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 25.06px !important;
  letter-spacing: 0.01em !important;
  padding: 10px 0;
}

.modal-divider {
  border: 1px solid #4b3a53;
  margin: 31px 0;
}

/* .connect-btn span {
  margin: 0 10px;
} */

.newuserlink {
  padding: 25px 0;
}

.newuserlink p {
  margin: 25px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.newuserlink p a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #136cfb !important;
}

.connect-id,
.viewpolygonlink {
  margin: 20px 0;
}

.connect-id p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #ffffff;
}

.connect-id img,
.viewpolygonlink img {
  margin: 0 10px;
}

.viewpolygonlink p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 113.3%;
  color: #ffffff;
}

/* checkbox custom */
.form-check-input:checked {
  background-color: #451463 !important;
  border-color: #ffffff !important;
}

.form-check-input:focus {
  border-color: #ffffff;
  outline: 0;
  /* box-shadow: 0px 0px 0px 8px rgb(25 118 210 / 16%); */
}

/* Defi page css */

.defi-dash-bg-grad {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 1px;
  border-radius: 9px !important;
}

.defi-dash-bg {
  background: #413049 !important;
  border-radius: 9px !important;
  padding: 26px 20px;
  cursor: pointer;
}

.defi-price-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #fcfcfc;
}

.defi-price-card h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
}

.private-sale-btn {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 0px 3px;
  border-radius: 5px !important;
  margin: 0 0 0 8px;
}

.Rectangle-defi-img {
  width: 51px;
  height: 51px;
}

.BGEM-price {
  width: 250px;
}

.greenstat-img {
  width: 100px;
}

.play_to_earn4 {
  width: 130px;
  margin: -35px 0;
}

.defi-cards-bg {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  padding: 20px;
}

.defi-card-header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #fcfcfc;
}

.defi-card-header img,
.swapdefi-coin img,
.swaper-coin img {
  width: auto;
}

.swapdefi-coin {
  margin: 20px 0;
  padding: 8px;
  background: linear-gradient(
      89.24deg,
      rgba(144, 44, 237, 0.08) 0.68%,
      rgba(246, 157, 77, 0.08) 99.4%
    ),
    #000000;
  border-radius: 9px;
}

.uper-coin-input {
  background: linear-gradient(
      89.24deg,
      rgba(144, 44, 237, 0.08) 0.68%,
      rgba(246, 157, 77, 0.08) 99.4%
    ),
    #000000;
  outline: none;
  padding: 0 10px;
  width: 160px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
  border: none;
}

.coinmax {
  padding: 1px 5px;
  background: #2d1c35;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.coin-drop-img1 {
  margin-right: 5px;
}

.coin-drop-img2 {
  margin-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.boom-img-coin {
  margin-right: 5px;
}

.swaper-coin {
  background: #000000;
  border: 4px solid #2d1c35;
  border-radius: 9px;
  padding: 8px;
  position: absolute;
  left: 45%;
}

.buy-btn-defi {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.remove-btn-defi {
  background: #413049;
  border-radius: 9px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.remove-btn {
  width: 100%;
  background: linear-gradient(89.95deg, #ff0000 0.01%, #a20202 99.93%);
}

.default-btn {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  width: 100%;
}

.swaper-headding p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
}

.swaper-headding img {
  width: 32px;
  margin: 0 3px;
}

.vesting-boom-img img {
  width: 231px;
}

.tab-content-defi {
  background: #413049;
  border-radius: 9px;
  padding: 1px;
}

.tabs-defi {
  border-radius: 9px;
  padding: 10px;
}

.tabs-defi-active {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  padding: 10px;
}

.defi-footer img {
  width: auto;
}

.defi-footer {
  /* position: absolute; */
  margin: 150px 0 0 0;
  bottom: 0;
}

.defi-footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.defi-footer-content a {
  cursor: pointer;
}

.defi-copright {
  padding: 10px 0;
  text-align: center;
  margin-top: 30px;
}

.defi-icons-margin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.defi-sale-private-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* game css */
.margin-top-game {
  margin-top: 8rem;
}

.game-card-bg {
  background: linear-gradient(180deg, #2f1c38 0%, #563367 100%);
  border-radius: 30px;
}

.gamelefsideimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

.game-card-detail {
  margin: 50px;
}

.game-card-detail h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 47px;
  color: #ffffff;
}

.game-desc,
.gamebtn-content {
  margin-top: 50px;
}

.game-detail {
  min-width: 100px;
}

.game-detail span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #b3b3b3;
}

.game-detail p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.game-app-logo {
  width: 130px;
}

.game-play-btn {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.gamebtn-content-border {
  margin-top: 50px;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  padding: 1px;
  border-radius: 9px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.gamebtn-1 {
  background: #36154a;
  border-radius: 9px;
  padding: 10px;
  width: 100%;
}

/* governance css */
.gover-card-bg {
  background: linear-gradient(90deg, #800c93 0%, #7f2abe 100%);
  border-radius: 20px;
  padding: 45px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.governance-heading-card {
  width: 55%;
}

.governance-heading-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 100%;
  color: #ffffff;
}

.governance-stat-slider {
  width: 25%;
  text-align: center;
}

.governance-stat-slider p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 47px;
  text-transform: uppercase;
  color: #ffffff;
}

.governance-stat-slider span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.goverance-bar-value {
  position: absolute;
  top: -5px;
  left: 45%;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 47px;
}

.w-100 {
  width: 100%;
}

.gove-col-left {
  width: 25% !important;
}

.gove-col-right {
  width: 75% !important;
}

.gove-col-left img {
  width: auto;
}

.governance-sidebar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.gov-table-header {
  border-radius: 9px 9px 0px 0px;
}

.governance-table thead tr th:first-child {
  border-radius: 9px 0 0 0;
}

.governance-table thead tr th:last-child {
  border-radius: 0 9px 0 0;
}

.governance-table tbody tr:nth-child(n) td:first-child {
  border-radius: 9px 0 0 9px !important;
}

.governance-table tbody tr:nth-child(n) td:last-child {
  border-radius: 0 9px 9px 0 !important;
}

.governance-table thead tr th {
  background: #2d1c35;
  padding: 20px 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff !important;
}

.governance-table tbody tr {
  margin-top: 20px;
  background: #2a0e37;
  border-radius: 8px;
}

.governance-table tbody tr td {
  padding: 20px 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff !important;
}

.gov-table-header {
  background: #2d1c35;
  border-radius: 9px 9px 0px 0px;
}

.expand-governance-table {
  padding: 20px;
  width: 100%;
  background: rgba(51, 29, 62, 0.6);
  border-radius: 0 0 9px 9px;
}

.Gov-Action-btn {
  background: #413049;
  border-radius: 9px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  margin-right: 20px;
}

.expanded-contet-box {
  padding: 20px;
}

.expanded-contet-box h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 10px;
}

.expanded-contet-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 30px;
}

/* Data table custom css */
.governace-data-table .rdt_Table {
  background: transparent;
}

.governace-data-table .rdt_TableHeadRow {
  background: #2d1c35;
  border-radius: 9px 9px 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.governace-data-table .rdt_TableRow {
  background: #2a0e37;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 20px;
  border: none;
}

.governace-data-table .rdt_TableRow svg {
  color: #ffffff;
}

.governace-data-table .rdt_ExpanderRow {
  background: rgba(51, 29, 62, 0.6);
  border-radius: 0 0 9px 9px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media screen and (max-width: 1135px) {
  .governance-heading-card,
  .governance-stat-slider {
    width: 100%;
    text-align: center;
  }

  .gove-col-right,
  .gove-col-left {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.shard-count {
  font-family: "BadBlockheadFull";
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

/* summon style */

.summoncard.card {
  background: #310a3d;
  border: none;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 15px;
}

.summon-border-steps-grad {
  padding: 1px;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  position: absolute;
  border-radius: 5px;
  top: -18px;
}

.summon-steps {
  background: #36154a;
  border-radius: 5px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
}

.summon-select-hunter {
  margin-top: 7rem !important;
  margin-bottom: 6rem !important;
}

/* .summon-step2-card {
  padding: 0 45px !important;
} */

.summon-card-header {
  background: #310a3d;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding: 20px !important;
}

.summon-pentagon-blocks {
  background: #b396bf;
  border-radius: 9px;
}

.block-summon-button {
  padding: 4px 15px;
  position: absolute;
  right: 0;
  left: 0;
  top: -40px;
  border: none;
  background: linear-gradient(89.95deg, #9429f5 0.01%, #f6a552 99.93%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 5px;
  width: 100%;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.summon-sidebar-block {
  background: #593c65;
  border-radius: 9px;
  padding: 14px 8px;
}

.summon-sidebar-block-active {
  background: #b396bf;
  border-radius: 9px;
  padding: 14px 8px;
}

.summon-selected-block-bg {
  background: #d9d9d9;
  border-radius: 5px;
  padding: 2px;
  width: 18px;
  height: 18px;
}

.summon-sidebar-block-border {
  padding: 5px;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px;
}

.summon-active-selected-block {
  margin: -18px 0 0 0;
}

.summon-card {
  background: #2e1140;
  border-radius: 0 0 9px 9px;
  padding: 50px 30px !important;
}

.summon-active-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summon-chacked-block {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.summon-price-block {
  background: #2e1240;
  border-radius: 10px;
  padding: 30px;
}

.active-summoning-price-block {
  position: relative;
  top: -35px;
  background: #2e1240;
  border-radius: 10px;
  padding: 30px;
}

.summon-price-time {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.summon-price-time-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.summon-price-time img {
  width: auto;
  margin-right: 5px;
}

.summon-btn {
  background: linear-gradient(89.95deg, #9429f5 0.01%, #f6a552 99.93%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  border: none;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.summon-card-subtitle {
  padding: 1px;
  background: linear-gradient(
    90deg,
    rgb(251, 174, 60, 0.4) 0%,
    rgb(143, 19, 255, 0.4) 100%
  );
}

.summon-subtitle {
  background: #381b4a;
  padding: 12px 20px;
  font-family: "BadBlockheadFull";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34.5px;
  letter-spacing: 0.01em;
  color: #ffffff;
  -webkit-text-stroke: 1px #3b6f03;
}

.summon-card-chance {
  padding: 1px;
  background: linear-gradient(
    90deg,
    rgb(143, 19, 255, 0.3) 0%,
    rgb(251, 174, 60, 0.3) 100%
  );
  width: 100%;
  margin-top: 30px;
}

.summon-chance {
  background: #381b4a;
  display: flex;
  justify-content: space-between;
}

.chance-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.chance-stats-subtitle {
  padding: 21px 30px;
}

.total-chance {
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.summon-card-chance-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.summon-card-chance-list-item {
  display: flex;
  justify-content: space-between;
}

.summon-card-chance-list-item-value {
  display: inline;
  max-width: 60px;
}

.summon-card-chance-list-item-title {
  justify-content: flex-start;
  display: flex;
  width: 56%;
}

.chance-titles {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding: 12px 20px;
}

.summon-character-shadow {
  margin: 0 auto;
  width: 172px;
  z-index: 1;
  position: relative;
  top: 50px;
}

.select-hunter-skeleton {
  max-width: 350px;
  z-index: 2;
  position: relative;
}

.summon-select-background {
  background: #2e1140;
  border-radius: 10px;
}

.equipments {
  display: flex;
  justify-content: space-evenly;
}

.item-squire {
  position: relative;
  width: 90px;
  height: 90px;
  background-color: #bbbbbb;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summon-missing-tag {
  position: absolute;
  top: 41px;
  left: 15px;
  right: 0;
  bottom: 0;
  z-index: 2;
  font-style: normal;
  font-family: "BadBlockheadFull";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #f6f0eb;
  -webkit-text-stroke: 1px #47435e;
}

.summon-not-selected-tag {
  position: absolute;
  top: 39px;
  left: 7px;
  right: 0;
  bottom: 0;
  font-style: normal;
  font-family: "BadBlockheadFull";
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #f6f0eb;
  -webkit-text-stroke: 1px #47435e;
}

.summon-lock-hunter {
  margin-top: 2.7rem;
  margin-bottom: 1.8rem;
}

.text-stroke {
  -webkit-text-stroke: 1px rgba(20, 73, 118, 1);
}

.lock-hunter {
  width: 190px;
  margin: 0 auto;
  box-shadow: inset 0px 0px 4px #000000;
  border-radius: 10px;
}

.lock-hunter-title {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  font-style: normal;
  font-family: "BadBlockheadFull";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  background: rgba(58, 162, 29, 1);
  box-shadow: inset 0px 0px 4px #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px;
  -webkit-text-stroke: 1px rgba(20, 73, 118, 1);
}

.lock-hunter-id {
  position: absolute;
  top: -32px;
  left: 0;
  right: 0;
  bottom: 0;
  font-style: normal;
  font-family: "BadBlockheadFull";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  background: rgba(19, 68, 109, 0.6);
  box-shadow: inset 0px 0px 4px #000000;
  display: flex;
  justify-content: center;
  padding: 8px;
  -webkit-text-stroke: 1px rgba(20, 73, 118, 1);
}

.summon-hero {
  max-width: 200px;
  margin: 30px auto;
}

.summon-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summon-tab {
  cursor: pointer;
  border-radius: 9px;
  padding: 10px;
  min-width: 110px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #d7d7d7;
}

.summon-tab-active {
  background: #413049;
}

@media screen and (max-width: 620px) {
  .summon-card {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 0 !important;
  }

  .summon-pentagon-blocks-border {
    max-width: 60px;
  }

  .block-summon-button {
    font-size: 10px;
  }

  .summon-sidebar-mobile-margin {
    margin: 20px;
  }

  .summon-side-hero-img {
    max-width: 100px !important;
    width: auto;
  }
  .summon-hero {
    max-width: 100px;
    top: 40%;
    left: 38%;
  }
  .singleproductcard.card {
    min-width: 100%;
    min-height: auto;
  }

  .singleproductcard-short.card {
    min-width: 100%;
    min-height: auto;
  }

  .column-direction {
    flex-direction: column;
  }

  /* .Artifacts-info img {
    width: 33px !important;
    height: 33px !important;
  } */
}

@media screen and (min-width: 620px) and (max-width: 700px) {
  .summon-hero {
    max-width: 130px;
    top: 40%;
    left: 40%;
  }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
  .summon-hero {
    max-width: 180px;
    top: 38%;
    left: 38%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .summon-hero {
    max-width: 225px;
    top: 38%;
    left: 38%;
  }
}

@media screen and (min-width: 620px) and (max-width: 1200px) {
  .summon-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
  }
}

/* loader css */

.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flexbox > div {
  flex: 0 0 25%;
  box-sizing: border-box;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999;
}

.mesh-loader {
  overflow: hidden;
  height: inherit;
  width: inherit;
}

.mesh-loader .circle {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #b147ff;
  border-radius: 50%;
  margin: -15px;
  -webkit-animation: mesh 3s ease-in-out infinite -1.5s;
  animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader > div .circle:last-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.mesh-loader > div {
  position: fixed;
  top: 50%;
  left: 50%;
}

.mesh-loader > div:last-child {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  50.1% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  50.1% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* store css */
.store-tier-1 {
  background: linear-gradient(90deg, #4eab17 0%, #258612 100%);
  border-radius: 0 0 9px 9px;
  padding: 5px !important;
}

.progress-bar.prgressbar {
  padding: 5px;
  text-align: end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
}

.progress-bar.store-bg-g {
  background: #49b308;
}

.progress-bar.store-bg-y {
  background: #fdcc00;
}

.progress-bar.store-bg-r {
  background: #fe3101;
}

.store-card-bg {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  padding: 30px;
  border-radius: 20px;
}

.daily-card-bg {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 20px;
  padding-top: 10px;
}

.storeimagerightside {
  border: 1px solid #7321c1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
}

.btn-wallet-bg {
  background: linear-gradient(
      89.24deg,
      rgba(144, 44, 237, 0.08) 0.68%,
      rgba(246, 157, 77, 0.08) 99.4%
    ),
    #000000;
  border-radius: 9px;
  padding: 15px;
  margin-top: 10px;
}

.w-stote-btn-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.x-stote-btn-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.03em;
}

.btn-openchest-bg {
  background: linear-gradient(89.95deg, #9429f5 0.01%, #f6a552 99.93%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  padding: 15px;
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.btn-openchest-bad {
  padding: 15px;
  margin-top: 10px;
  font-style: normal;
  font-size: 22px;
  object-fit: contain;
}

.storeright-img {
  object-fit: cover;
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
}

.store-header-h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
}

.store-card-shadow {
  filter: drop-shadow(0px 0px 33px rgba(0, 0, 0, 0.75));
  height: 100% !important;
}

.store-card-headername {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.03em;
}

.store-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #331346;
  border-radius: 0 0 9px 9px;
  padding: 5px !important;
}

.store-card-body-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.01em;
}

.store-card-body-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.storebackimg {
  width: auto;
  margin-right: 5px;
}

.carouselimg img {
  width: auto;
}

.carouselimg .react-multi-carousel-item {
  width: 215px !important;
}

.storetooltip .tooltip {
  --bs-tooltip-bg: #36104e !important;
  /* box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2) !important; */
}

.gstoretooltip .tooltip {
  --bs-tooltip-bg: #1f8909 !important;
  /* box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2) !important; */
}

.video-store-container {
  position: relative;
  border-radius: 15px;
}

.video-store {
  /* position: absolute; */
  border-radius: 15px;
  width: 100%;
  max-width: 1300px;
  /* min-height: 632px; */
  object-fit: contain;
  /* max-height: 600px; */
}

.btn-open-chest {
  position: absolute;
  top: 72%;
  /* left: 45%; */
  transform: translateX(-50%);
  left: 50%;
  cursor: pointer;
}

.btnopen-chest {
  position: absolute;
  top: 290px;
  /* left: 38%; */
  transform: translateX(-50%);
  left: 50%;
}

.btn-buy-now {
  cursor: pointer;
  /* left: 44%; */
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  top: 871px;
}

.open-chest-button {
  cursor: pointer;
}

.store-right {
  position: relative;
}

.video-store-show {
  opacity: 1;
  visibility: visible;
}

.video-store-hide {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.video-store-img {
  /* position: fixed; */
  border-radius: 15px;
  width: 100%;
  max-width: 1300px;
  min-height: 632px;
  object-fit: cover;
}

.v-store-img-hide {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.v-store-img-show {
  opacity: 1;
  visibility: visible;
}

.store-card {
  width: 100%;
  position: absolute;
  top: 0;
  padding: 19px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.altar-card {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.welcome {
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
}

.store-card-mw.card {
  max-width: 350px;
  min-width: 350px;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

.hero-container {
  display: flex;
  justify-content: flex-start;
  left: 0;
}

.section-welcome {
  background: linear-gradient(90deg, #ffa95a 0%, #b147ff 100%);
}

.font-face-bad-blockhead {
  font-family: "BadBlockheadFull";
}

.open-chest-buy-banner {
  position: relative;
}

.btn-buy-openchest {
  position: absolute;
  top: 73%;
  /* left: 43%; */
  transform: translateX(-50%);
  left: 50%;
  width: 200px;
}

.btn-chest-counter {
  /* left: 40%; */
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  top: 70%;
  width: 230px;
}

.btn-chest-padlock {
  /* left: 45.5%; */
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  top: 27%;
  width: 100px;
}

.btn-chest-rectangle {
  /* left: 30%; */
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  top: 8%;
  width: 500px;
}

.btn-chest-rectangle-text {
  /* left: 38%; */
  position: absolute;
  top: 20%;
  font-style: normal;
  font-size: 18px;
  text-shadow: 1px 1px 10px #c0520a;
}

.btn-chest-text {
  /* left: 40%; */
  transform: translateX(-50%);
  left: 52%;
  position: absolute;
  top: 46.5%;
  font-style: normal;
  font-size: 16px;
  text-shadow: 1px 1px 10px #c0520a;
}

.btn-chest-counter-text {
  /* left: 40%; */
  transform: translateX(-50%);
  left: 62%;
  position: absolute;
  top: 46.5%;
  font-style: normal;
  font-size: 16px;
  text-shadow: 1px 1px 10px #c0520a;
}

/* NEW PAGE DAILY */

.mr-10 {
  margin-right: 41px !important;
}

.mr-11 {
  margin-right: 10px !important;
}

.font-helper4{
  font-family: Poppins;
  font-weight: 700;
  font-size: 80px;
}

.font-helper {
  font-family: Poppins;
  font-weight: 700;
  font-size: 40px;
}
.font-helper1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 117px;
  line-height: 89px;
  letter-spacing: -8px;
}

.font-helper2 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 39px;
  line-height: 58.5px;
  letter-spacing: -1px;
}

.font-helper3 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
}

.box-helper {
  width: 369px;
  height: 90px;
}

.my-special {
  margin-top: 197px !important;
  margin-bottom: 216px !important;
}

.claim-reward-header {
  margin-top: 120px !important;
}

.buy-token-error {
  color: "red";
  align-self: center;
}

.claim-reward-countdown {
  margin-top: 37px !important;
  margin-bottom: 150px !important;
}

.countdown-container {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  background-clip: text;
  color: transparent;
  font-family: "Poppins";
}

.countdown-label {
  background: transparent;
  color: #ffffff;

  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.05em;
}

.countdown-time-section {
  font-size: 96px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0.01em;
  text-align: center;
  height: 122px;
}

.image-helper {
  width: 285px;
  height: 285px;
}

.mt-50 {
  margin-top: 90px;
}

@media screen and (min-width: 950px) and (max-width: 1350px) {
  .btn-buy-openchest {
    width: 125px;
  }
  .btn-openchest-bad {
    font-size: 19px !important;
  }
  .btn-open-chest {
    top: 70.5%;
  }
}

@media screen and (min-width: 800px) and (max-width: 950px) {
  .btn-buy-openchest {
    width: 100px;
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .btn-buy-openchest {
    width: 75px;
  }
}

@media screen and (min-width: 200px) and (max-width: 950px) {
  .countdown-time-section {
    font-size: 70px;
    line-height: 80px;
  }
  .image-helper {
    width: 150px;
    height: 150px;
  }

  .font-helper1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 0;
  }
  .font-helper2 {
    font-size: 30px;
    line-height: 30px;
  }

  .countdown-label {
    font-size: 15px;
    line-height: 20px;
  }

  .claim-reward-countdown {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .btn-buy-openchest {
    width: 75px;
  }
}

/* .pad-lockimg{
  width: 100px;
} */

.bg-img-openchest img {
  min-height: 296px;
  object-fit: cover;
}

@media screen and (min-width: 950px) and (max-width: 1180px) {
  .btn-chest-padlock {
    width: 80px;
  }
  .btn-chest-rectangle {
    /* left: 28%; */
    width: 486px;
  }
}

@media screen and (min-width: 800px) and (max-width: 950px) {
  /* .btn-chest-rectangle{
    left: 20%;
   } */
  .btn-chest-padlock {
    width: 80px;
  }
  /* .btn-chest-counter{
     left: 35%;
   } */
  .btn-open-chest {
    top: 68.5%;
  }
  .btn-openchest-bad {
    padding: 12px;
    font-size: 16px;
  }
}

@media screen and (min-width: 750px) and (max-width: 800px) {
  /* .btn-chest-rectangle {
    left: 15%;
  }
  .btn-chest-counter{
    left: 34%;
  } */
  .btn-chest-padlock {
    width: 60px;
  }
  .btn-open-chest {
    top: 67.5%;
  }
  .btn-openchest-bad {
    padding: 8px;
    font-size: 16px !important;
  }
}
@media screen and (min-width: 550px) and (max-width: 750px) {
  .video-container {
    padding-bottom: 85.25% !important;
  }
  .btn-chest-rectangle {
    width: 352px;
    top: 2% !important;
  }
  /* .btn-chest-rectangle {
    left: 21%;
  } */

  .btn-chest-padlock {
    width: 50px;
    top: 39%;
  }
  .btn-chest-counter {
    /* left: 38%; */
    top: 68%;
    width: 145px;
  }
  .btn-chest-text {
    font-size: 10px;
  }
  .btn-open-chest {
    top: 68%;
  }
  .btn-openchest-bad {
    font-size: 12px;
    padding: 8px;
  }
}

@media screen and (min-width: 535px) and (max-width: 550px) {
  .video-container {
    padding-bottom: 85.25% !important;
  }
  .btn-chest-rectangle {
    width: 352px;
  }
  /* .btn-chest-rectangle {
    left: 13%;
  } */

  .btn-chest-padlock {
    width: 50px;
    top: 37%;
  }
  .btn-chest-counter {
    /* left: 36%; */
    top: 68%;
    width: 145px;
  }
  .btn-chest-text {
    font-size: 10px;
  }
  .btn-open-chest {
    top: 68%;
  }
  .btn-openchest-bad {
    font-size: 10px;
    padding: 6px;
  }
  .cardbg-equiment-img {
    max-width: 100px !important;
    left: 7px !important;
  }
  .card-container {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 535px) {
  .video-container {
    padding-bottom: 108.25% !important;
  }
  .active-summoning-price-block {
    top: -17px !important;
  }
  .btn-chest-rectangle {
    width: 249px;
    top: 1% !important;
  }
  /* .btn-chest-rectangle {
    left: 17%;
  } */

  .btn-chest-padlock {
    width: 40px;
    top: 38%;
  }
  .btn-chest-counter {
    /* left: 30%; */
    top: 68%;
    width: 145px;
  }
  .btn-chest-text {
    font-size: 10px;
  }
  .btn-chest-rectangle-text {
    font-size: 9px !important;
    padding: 0rem 1rem !important;
  }
  /* .btn-chest-padlock {
    left: 43.5%;
    top: 38%;
} */
  .btn-chest-rectangle img {
    height: 75px;
  }
  .btn-open-chest {
    top: 68%;
  }
  .btn-openchest-bad {
    font-size: 10px;
    padding: 6px;
  }
  .item-squire {
    width: 60px !important;
    height: 60px !important;
  }
  .summon-missing-tag {
    top: 26px !important;
    left: 4px !important;
    font-size: 11px !important;
  }
  .summon-not-selected-tag {
    top: 17px !important;
    left: 2px !important;
    font-size: 11px !important;
  }
  .block-summon-button {
    padding: 4px 3px !important;
  }
  .chance-titles {
    font-size: 16px !important;
    padding: 12px 3px !important;
  }
  .chance-stats-subtitle {
    padding: 21px 22px !important;
  }
  .summon-card-chance-list {
    font-size: 15px !important;
  }
  .total-chance {
    font-size: 50px !important;
    margin-top: 13px !important;
  }
  .select-hunter-skeleton {
    width: 200px !important;
    margin-top: 5rem !important;
    margin-bottom: 2rem !important;
  }
  .summon-character-shadow {
    top: 0 !important;
  }
  .summon-select-hunter {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .herovideo {
    width: 139px !important;
    height: 142px !important;
  }
  .cardbg-equiment-img {
    max-width: 100px !important;
    left: 7px !important;
  }

  .card-container {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.btn-chest-rectangle-text {
  background: #111111b8;
  padding: 0.5rem 3rem;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .btn-chest-counter-text {
    font-size: 10px;
  }
}

@media screen and (min-width: 800px) {
  .btn-chest-rectangle-text {
    font-size: 15px !important;
    padding: 0.5rem 3rem !important;
  }
  .btn-chest-rectangle {
    width: 249px;
    top: 6% !important;
  }
}

@media screen and (max-width: 375px) {
  /* .btn-chest-rectangle {
    left: 12%;
} */
  .btn-chest-rectangle-text {
    font-size: 15px;
  }
  .btn-openchest-bad {
    font-size: 8px;
    padding: 5px;
  }
  .btn-open-chest {
    top: 57%;
  }
}

@media screen and (max-width: 280px) {
  .btn-chest-rectangle-text {
    font-size: 15px;
  }
  .btn-chest-rectangle {
    width: 168px;
  }
  .btn-chest-padlock {
    width: 30px;
    top: 53%;
  }
  .btn-chest-padlock {
    width: 30px;
    top: 53%;
    /* left: 42.5%; */
  }
  .btn-chest-counter {
    /* left: 15%; */
    top: 72%;
  }
  .btn-open-chest {
    top: 49%;
  }
  .btn-openchest-bad {
    font-size: 6px;
    padding: 4px;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.btn-open-v-hunter {
  border-radius: 10px;
}

.header-nowrap {
  white-space: nowrap;
}

/* NEW MODAL LOOKS */

.connect-wallet-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  font-family: Poppins;
  margin: -23px 0 4px 30px;
}

.other-wallets-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  font-family: Poppins;
  margin: 0 0 4px 30px;
}

.other-wallet-description {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  font-family: Poppins;
  margin: 0 0 4px 30px;
}

.network {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  font-family: Poppins;
}

.other-wallet-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20.88px;
  font-family: Poppins;
}

.other-wallet-network {
  font-size: 9px;
  font-weight: 500;
  line-height: 13.5px;
  font-family: Poppins;
  color: #572580;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0 6px;
  margin-left: 10px;
}

.wallet-address-font {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  font-family: Poppins;
  padding: 0 8px 0px 30px;
}

.other-address-font {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  font-family: Poppins;
  padding: 0 8px 0 3px;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 640px) {
  .modal-connect-wallet {
    margin-top: 3rem !important;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 7%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1464px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 7%;
  }
}

@media only screen and (min-width: 1465px) and (max-width: 1600px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 5.4%;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1879px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 15%;
  }
}

@media only screen and (min-width: 1880px) and (max-width: 2180px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 15.7%;
  }
}

.buy-boom-input {
  border-color: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  text-align: center;
  color: white;
  align-items: center;
}

@media only screen and (min-width: 2180px) {
  .modal-connect-wallet {
    position: absolute !important;
    top: 3rem;
    right: 24%;
  }
}

.vesting {
  color: white !important;
  padding-top: 80px;
  padding-bottom: 80px;
}

.vesting * {
  color: white !important;
}

.token-section {
  color: white !important;
  padding-top: 80px;
  padding-bottom: 80px;
}

.token-section {
  padding: 56px;
  background: linear-gradient(270deg, #781e87 0%, #69229c 100%);
  border-radius: 20px;
}

.create-section {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
}

.create-section.title > svg {
  font-size: 1 rem;
  color: #979797;
}

.create-button {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  height: 45px;
  color: white;
}
