.card-black {
	background: rgba(45, 28, 53, 0.6);
	padding: 15px;
	border-radius: 9px
}

.row {
	margin-bottom: 1rem;
}

.h-100 {
	height: 100%;
}

.d-block {
	display: block;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.store-header {
	border-radius: 9px 9px 0 0;
	position: relative;
}

.store-header::after {
	content: ' ';
	font-size: 0;
	position: absolute;
	bottom: 0;
	display: block;
	height: 1px;
	left: 0;
	width: 100%;
	background: linear-gradient(to right, rgb(132, 34, 228), rgb(251, 174, 60));
}

.store-header h1 {
	font-size: 24px;
	margin: 0;
}

/* .btn {
	border: none;
	background: none;
	height: auto;
	font-size: inherit;
} */

.btn-review-order {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	color: #fff;
	background: #413049;
	border-radius: 6px;
	padding: 6px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 1px;
}

.btn-ghost {
	display: block;
	width: auto;
	height: auto;
	background: transparent;
	border: none;
	font-size: inherit;
	line-height: inherit;
	padding: 0;
	margin: 0;
}

.icon-shopping-basket {
	height: 16px;
	width: auto;
	display: inline-block;
	margin-right: 6px;
}

.badge {
	background: #fff;
	border-radius: 100%;
	display: inline-block;
	line-height: 1;
	color: #2D1C35;
	padding: 2px 4px;
	font-size: 80%;
	margin-left: 3px;
}

.btn-chest {
	background: #321C3F;
	padding: 16px 0;
	font-weight: 600;
	font-size: 20px;
	color: #fff;
	margin: 0	auto;
	border-radius: 9px;
	width: 80%;
	display: block;
	border: 3px solid rgba(0, 0, 0, 0);
	text-align: center;
}

.btn-normal-mint {
	border-color: rgba(60, 211, 171, 1);
}

.btn-traveler-lockbox {
	border-color: rgba(49, 136, 213, 1);
}

.btn-warriors-chest {
	border-color: rgba(142, 62, 249, 1);
}

.btn-guild-safe {
	border-color: rgba(169, 16, 16, 1);
}

.w-90 {
	width: 90%;
	margin: 0 auto;
}


.form-black {
	background: rgba(0,0,0,1);
	border-radius: 9px;
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.full-width {
	display: inline;
	width: auto;
	height: auto;
}

.h-286 {
	height: 286px;
}

.btn-cta {
	display: block;
	width: 100%;
	border:none;
	background: linear-gradient(90deg, #8F13FF 0%, #F6A552 100%);
	font-weight: 600;
	color: #fff;
	border-radius: 9px;
	height: 44px;
	line-height: 44px;
}

.btn-silent {
	display: block;
	width: 100%;
	font-weight: 600;
	border-radius: 9px;
	height: 44px;
	line-height: 44px;
	background: #413049;
	color: #fff;
}

.btn-silent-in {
	width: 60px;
	font-weight: 600;
	border-radius: 9px;
	height: 44px;
	line-height: 44px;
	background: #413049;
	color: #fff;
	font-size: 22px;
	margin: 0;
	padding: 0;
}

.fc-silent-in {
	font-weight: 600;
	background: transparent!important;
	border: none;
	height: 44px;
	line-height: 44px;
	background: #413049;
	color: #fff;
	text-align: center;
	font-size: 18px;
	margin: 0;
	padding: 0;
	width: calc(100% - 120px);
}

.fc-buybox {
	display: block;
	width: 100%;
	background: transparent!important;
	font-weight: 600;
	margin: 0;
	border: none;
	height: 44px;
	line-height: 44px;
	padding: 0;
	background: #413049;
	color: #fff;
	text-align: center;
	font-size: 22px;
}

.fa-chevron-left {
	cursor: pointer;
}


.btn-gradient {
	background: linear-gradient(90deg, #8F13FF 0%, #F6A552 100%);
	border: none;
	margin: 1rem auto;
	border-radius: 9px;
	padding: 0.5rem 0;
	font-weight: 600;
	display: block;
	color: #fff;
	width: 90%;
}

.cartTotal {
	display: flex;
	width: 100%;
	font-size: 16px;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	color: #fff;
	padding: 15px 15px 0;
	background: rgba(55, 38, 63, 0.6);
}

.cartProducts {
	padding: 0 15px;
}

.cartProduct {
	padding: 15px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.cartTotalAmount {
	text-align: right;
}

.cartAction {
	background: rgba(55, 38, 63, 0.6);
	padding-bottom: 15px;
	padding-top: 15px;
}

.cartAction .btn {
	margin: 0 auto;
}

.btn-times {
	background: none;
}
