.social-media__container {
  max-width: 1000px;
  margin: auto;
  padding: 100px 40px;
  /* display: flex; */
  /* justify-content: space-between; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.social-media-icon--container {
  position: relative;
}

.social-media--text {
  position: absolute;
  top: -45px;
  right: -70px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 18px;
  background: white;
  color: #572580;
}

.social-media-icon--container svg {
  width: 40px;
}

.network-chip {
  height: 18px !important;
}
.network-chip span {
  font-weight: 600 !important;
  font-family: "Poppins" !important;
}
p,
label {
  color: white !important;
}

@media screen and (max-width: 768px) {
  .social-media__container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 70px 60px;
    /* padding: 100px 20px; */
  }

  .social-media--text {
    padding: 2px 5px;
    top: -30px;
    right: -55px;
  }
  .social-media-icon--container svg {
    width: 30px;
  }
}
