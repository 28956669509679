/* Swipper custom css */

.swiper-button-next,
.swiper-button-prev {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 28px 20px;
  color: white;
}

.swiper-button-prev {
  background-image: url("../../assets/store/forward-next.png");
}

.swiper-button-next {
  background-image: url("../../assets/store/forward-next.png");
  rotate: 180deg;
  right: -22px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

/* .chest-counter {
  font-family: "BadBlockheadFull";
} */

/******** media queries ********/

@media screen and (max-width: 650px) {
  .swiper-button-prev {
    left: -22px !important;
  }
  .chest-counter {
    top: 3px !important;
    right: 3px !important;
  }
  .chest-counter-font {
    font-size: 13px !important;
  }
  .tier1 {
    max-width: 68% !important;
  }
  .tier2 {
    max-width: 73% !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 70% !important;
  }
}

@media screen and (max-width: 550px) {
  .swiper-button-next,
  .swiper-button-prev {
    background-size: 50% !important;
  }
  .swiper-button-prev {
    left: -5px !important;
  }
}
