@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

p {
  margin-bottom: 0px !important;
}
.main {
  padding-top: 120px;
  background: linear-gradient(
    91.93deg,
    #421341 0%,
    #451e69 47.87%,
    #400c42 96.74%
  );
  color: #ffffff;
  width: 100%;
  height: 100%;
}
.green {
  color: #9eea16 !important;
}
.green-active {
  color: #7dfb4b;
}
.orange-active {
  color: #ffa841;
}
.red-active {
  color: #ea1a19;
}
.blue {
  color: #4faeff !important;
}
.purpal {
  color: #c84fff !important;
}
.orange {
  color: #ff8500 !important;
}

.container-x {
  max-width: 1320px;
  margin: auto;
  padding: 0 10px;
}
.fixedheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

/* .headerM{
    display: flex;
    justify-content: space-between;
    align-items: center;
     background: transparent;
     height: 120px;
  }
  .logo-img{
      width: 196px;
      
  } */

.left-header {
  display: flex;
  align-items: center;
  color: #ffffff !important;
}
.left-header .market img {
  margin-left: 10px;
  margin-right: 10px;
}
.left-header .market p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}
.left-header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
}
.right-header {
  display: flex;
  align-items: center;
}
.right-header a p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0 10px;
}
.right-header a .selectedp {
  font-weight: 600 !important;
}
.right-header .cart {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 10px;
  position: relative;
}
.right-header .cart-badge {
  position: absolute;
  background: #ff0000;
  border-radius: 16px;
  right: -7px;
  top: -7px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idnumber {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 13px 15px;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 10px;
}
.right-header .menu {
  align-items: center;
  padding: 15px;
  justify-content: center;
  background: #300a3c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 0 10px;
}
.photo {
  height: 140px;
  width: 140px;
  padding-left: 12px;
}
.core-text {
  padding-top: 14px;
}

.bg-header {
  background-color: rgba(0, 0, 0, 0.7);
}
.fixedTop {
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.mobile-nav-links p {
  padding: 10px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0 10px;
}
.mobile-nav-links :hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 9px;
}

.mobile-nav-content {
  left: 0;
  right: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 999;
}

.p-43 {
  padding: 43px !important;
  border-radius: 0 0 9px 9px;
}

.ticketimg {
  height: 100%;
  /* height: calc(100vw/6) !important; */
}

.hero-banner {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.hero-banner img {
  width: 100%;
}
.sortbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(45, 28, 53, 0.8);
  border-radius: 9px 9px 0px 0px;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
}

.display-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(45, 28, 53, 0.8);
  border-radius: 9px;
}
.sort-dropdown {
  display: flex;
  align-items: center;
}
.sort-dropdown .Drop-down {
  margin: 0 20px;
}
.powerbar img {
  width: 38px;
  /* width: 45px; */
}

.dropdown-menu {
  background: #413049 !important;
  color: #ffffff;
  width: 255px;
  z-index: 999;
  display: grid;
  border-radius: 9px;
}

.dropdown-menu a {
  padding: 10px;
}
.modal-content img,
.cart img,
.menu img,
.dropdown-menu img,
.sort-mobile-menu img,
.Sidebar-Mobile img,
.single-power-badge img,
.Artifacts-info img,
.table-card img,
.image-copy,
.connected-modal img {
  display: initial;
  width: auto;
  object-fit: inherit;
}

.dropdown-item {
  width: auto !important;
  margin: 5px 10px;
}
.dropdown-menu :hover,
.dropdown-menu :active,
.dropdown-menu :focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
}
.ActiveSelect {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.sortbar h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  margin: 0;
}
.border-b {
  width: 1650px;
  background: 1px linear-gradient(90deg, #952af4 0%, #f3a157 100%);
}

.drop {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 1px;
  border-radius: 9px !important;
}

.drop-btn {
  background: #413049 !important;
  border-radius: 9px !important;
  padding: 6px 12px;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.search-bar {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
}
.search-bar input {
  color: #ffffff;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  outline: none;
  appearance: none;
  border: none;
  width: 80%;
  padding: 15px 10px 15px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
::placeholder {
  color: #ffffff;
}
.search-icon {
  top: 18px;
  right: 18px;
}
.col-left {
  width: 20% !important;
}

.col-left img {
  width: auto;
  display: initial;
  height: auto;
}
.col-right {
  width: 80% !important;
}

.btn-primary-colapse {
  margin: 23px 0 0 0;
  padding: 15px 20px;
  background: #2d1c35 !important;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2) !important;
}

.card-collapse.card {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 0px 0px 10px 10px;
  border: none;
}
.colapse-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.border-radiusB {
  border-radius: 10px 10px 10px 10px !important;
}
.border-radiusA {
  border-radius: 10px 10px 0px 0px !important;
}
.cardbody-content.card-body {
  padding: 20px;
  border-image: linear-gradient(90deg, #d1903c 0%, #7321c1 100%) 1;
  border-top: 1px solid;
}

.infocard {
  background: #300a3c !important;
  border-radius: 10px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.table-card {
  background: #331346;
  border-radius: 9px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.table-card td img {
  margin-left: 10px;
}

.mataid,
.namecard {
  padding: 20px 30px !important;
}
.image-copy {
  cursor: pointer;
}
.owner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.cardbody-content .row-1 p {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}

.single-power-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px;
  padding: 1px;
  margin-top: 8px;
}

.single-power-badge {
  position: relative;
  padding: 2px 40px;
  background: #36154a;
  /* border: 1px solid; */
  /* border-image: linear-gradient(90deg, #FBAE3C 0%, #8F13FF 100%) 1; */
  border-radius: 9px;
}
.single-power-badge img {
  position: absolute;
  left: -20px;
  top: -5px;
}

.singlecard-header.singlecard-header {
  background: #310a3d;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding: 20px 30px !important;
  border-radius: 9px 9px 0 0;
}

.cardbody-content .row-6 p {
  margin-left: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}
.cardbody-content .row-1 Label {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  --bs-card-border-width: 0px !important;
}

.badge-bid {
  border: 1px solid #4b3a53;
  border-radius: 9px;
  padding: 8px 20px;
}

@media screen and (min-width: 1024px) and (max-width: 1372px) {
  .badge-bid {
    padding: 8px;
  }
  .badge-bid img {
    margin-right: 5px;
  }
  .cardbody-content .row-1 Label {
    margin-left: 5px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .col-left {
    width: 25% !important;
  }
  .col-right {
    width: 75% !important;
  }
  .cardbody-content .row-1 p {
    font-size: 16px;
  }
}

.Filterbtn {
  display: none !important;
}

.Sidebar-Mobile {
  display: none;
}
.modal-dialog.Sidebar-Mobile {
  max-width: 100vw;
  background: #2d1c35;
  margin: 0;
  color: #ffff;
  border-radius: 30px 30px 9px 9px;
  top: 25%;
}
.filterheader {
  border-radius: 30px 30px 0 0;
  padding: 15px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
}
.filterheader h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.modal-body.filterbody {
  /* margin-bottom: 118px; */
  max-height: 100%;
  overflow-y: scroll;
}

.modal-footer.filterfooter {
  display: flex;
  background: #fba562;
  border-radius: 9px;
  border-top: 0;
  padding: 30px 20px;
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
}
.resetfilter-btn-grad {
  width: 45%;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 10px 35px;
}
.reset-btn.btn {
  border-radius: 9px;
  background: #413049 !important;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.Applyfilter-btn-grad {
  width: 45%;
}
.Applysort-btn-grad {
  width: 100%;
}
.sort-mobile-menu a {
  padding: 5px 12px;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
}
.Apply-btn.btn {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.connect-btn-grad {
  border-radius: 100px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 10px 31px;
}

.connect-btn.btn {
  background: rgb(251, 165, 98);
  background: linear-gradient(
    90deg,
    rgba(251, 165, 98, 1) 0%,
    rgba(181, 75, 247, 1) 100%
  );
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 7px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-card-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stat-w-min {
  min-width: 75px;
}

@media screen and (min-width: 912px) and (max-width: 1372px) {
  .modal-dialog.Sidebar-Mobile {
    top: 30%;
  }
}

@media screen and (max-width: 280px) {
  .Artifacts-content {
    justify-content: center !important;
  }
  .sortbar {
    display: block !important;
  }
  .single-heroimg {
    width: 200px !important;
    height: 200px !important;
  }
  .modal-dialog.Sidebar-Mobile {
    display: flex;
    background: #341d3f;
  }
  .search-bar {
    display: none;
  }
  .sort-dropdown {
    display: none;
  }
  .Filterbtn {
    display: flex !important;
    align-items: center;
  }
  .filterbtn-margin {
    margin-left: 10px !important;
  }
  .col-left {
    display: none;
    width: 100% !important;
  }
  .col-right {
    width: 100% !important;
  }
  .cardbody-content .row-1 p {
    font-size: 16px;
  }
  .heroimg {
    height: 230px !important;
  }
  .cardbody-content.card-body {
    border: none;
    background: #341d3f;
  }
  .card-collapse.card {
    background: #341d3f;
    box-shadow: none;
  }
  .btn-primary-colapse {
    background: none !important;
    box-shadow: none !important;
  }
  .grad-border-bottom {
    border: 1px solid;
    border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
    margin: 10px 0;
  }
  .btn-primary-colapse {
    padding: 15px 0;
  }

  .cardbg-UNCOMMON {
    padding: 50px 10px !important;
  }
  .heroimg {
    height: 180px !important;
  }
  .golden-logo {
    width: 50px;
  }
  .powerbar img {
    width: 30px;
  }
  .name-logo {
    width: 80px;
  }

  .name-card .name {
    font-size: 18px !important;
  }
  .name-card .rarity {
    font-size: 16px !important;
  }
  .name-card .level {
    font-size: 16px !important;
  }
  .stat-card-detail {
    display: block;
  }
  .single-stat {
    margin-top: 10px !important;
  }
  .single-stat img {
    width: auto;
    object-fit: inherit;
  }
  .price-card .time {
    font-size: 16px !important;
  }
  .seemore {
    padding: 8px 60px !important;
    font-size: 16px !important;
    margin: 10px 0 100px 0 !important;
  }
}

.name-card {
  padding: 10px;
}
.price-card {
  padding: 10px;
}
.price-card img {
  width: auto;
  object-fit: inherit;
}

.stat-card-item {
  margin-top: 10px;
}
.Artifacts-info {
  text-align: center;
}
.Artifacts-info p {
  margin-top: 10px;
}

.btn-close-modal {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #ffffff;
  border: 0;
}

.single-stat {
  margin-top: 10px !important;
}
.single-stat img {
  width: auto;
  object-fit: inherit;
}

@media screen and (max-width: 650px) {
  /* .Artifacts-content{
       display: block !important;
    } */
  .Artifacts-content .Artifacts-info {
    margin-top: 10px;
  }
  .stat-card-detail {
    display: block;
  }
  .name-card {
    padding: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
  .ownerid {
    max-width: 200px !important;
  }
  .single-price-card {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .single-stat {
    margin-top: 10px !important;
  }
  .single-stat img {
    width: auto;
    object-fit: inherit;
  }
}
@media screen and (min-width: 281px) and (max-width: 983px) {
  .single-heroimg {
    width: 250px !important;
    height: 250px !important;
  }
}
@media screen and (max-width: 983px) {
  .defi-footer img {
    width: 40px !important;
  }
  .single-stat {
    margin-top: 10px !important;
  }
  .single-stat img {
    width: auto;
    object-fit: inherit;
  }
  .single-productcard {
    width: auto !important;
    height: auto !important;
  }
  .singleproductcard.card {
    width: auto !important;
  }

  .name-card .name {
    font-size: 22px !important;
  }
  .name-card .rarity {
    font-size: 18px;
  }
  .name-card .level {
    font-size: 18px;
  }
  .owner {
    font-size: 16px;
  }
  .ownerid {
    font-size: 16px !important;
    max-width: 220px;
  }
  .singlecard-header.singlecard-header {
    font-size: 16px;
  }
  .margin-bott {
    margin-bottom: 200px;
  }
  .priceinfo-mobile {
    position: fixed !important;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 !important;
  }
  .assetinfocard.card {
    width: 100% !important;
  }
}

@media screen and (min-width: 984px) and (max-width: 1250px) {
  .single-productcard {
    width: 380px !important;
    height: 380px !important;
  }
  .singleproductcard.card {
    width: 380px !important;
  }
  .single-heroimg {
    width: 300px !important;
    height: 300px !important;
  }
  .name-card .name {
    font-size: 22px !important;
  }
  .name-card .rarity {
    font-size: 18px;
  }
  .name-card .level {
    font-size: 18px;
  }
  .owner {
    font-size: 16px;
  }
  .ownerid {
    font-size: 16px !important;
  }
  .singlecard-header.singlecard-header {
    font-size: 16px;
  }
  .assetinfocard.card {
    width: 381px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .powerbar img {
    width: 45px;
  }
  .powerbar {
    right: 15px !important;
  }
}

@media screen and (min-width: 388px) and (max-width: 1135px) {
  .tier-slider {
    width: 60% !important;
    padding: 10px !important;
  }
}
@media screen and (max-width: 388px) {
  .defi-dash-bg {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .BGEM-price {
    width: auto !important;
  }
  .defi-icons-margin {
    display: block !important;
  }
  .defi-icons-margin img {
    margin-top: 10px !important;
    width: auto;
  }
  .defi-sale-private-flex {
    display: block !important;
  }
  .tier-slider {
    width: 100% !important;
    padding: 10px !important;
  }
  .assattier {
    display: block !important;
  }
  .tier-slider h3 {
    font-size: 22px !important;
  }
  .tiercard {
    margin-bottom: 0 !important;
  }
  .tier-slider {
    width: 100% !important;
    padding: 10px !important;
  }
  .assattier {
    border-radius: 16px !important;
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .tier-info h3 {
    font-size: 22px !important;
  }
  .tier-header {
    font-size: 12px !important;
    padding: 10px 5px !important;
  }
  .tier-info p {
    display: none;
    font-size: 12px !important;
  }
  .tier-slider h3 {
    display: none;
  }
  .tier-info {
    padding: 5px 5px !important;
  }
  .min-bar-value {
    display: none;
    font-size: 14px;
  }
  .max-bar-value {
    display: none;
    font-size: 14px;
  }
  .tier-slider {
    display: flex;
    align-items: center;
  }
  .middle-bar-value {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 281px) and (max-width: 1135px) {
  .tier-slider h3 {
    font-size: 22px !important;
  }
  .tiercard {
    margin-bottom: 0 !important;
  }
  /* .tier-slider {
      width: 50% !important;
      padding: 10px !important;
  } */

  .assattier {
    border-radius: 16px !important;
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .tier-info h3 {
    font-size: 22px !important;
  }
  .tier-header {
    font-size: 12px !important;
    padding: 10px 5px !important;
  }
  .tier-info p {
    display: none;
    font-size: 12px !important;
  }
  .tier-slider h3 {
    display: none;
  }
  .tier-info {
    padding: 5px 5px !important;
  }
  .min-bar-value {
    display: none;
    font-size: 14px;
  }
  .max-bar-value {
    display: none;
    font-size: 14px;
  }
  .tier-slider {
    display: flex;
    align-items: center;
  }
  .middle-bar-value {
    font-size: 18px !important;
  }

  .modal-dialog.Sidebar-Mobile {
    display: flex;
    background: #341d3f;
  }
  .search-bar {
    display: none;
  }
  .sort-dropdown {
    display: none;
  }
  .Filterbtn {
    display: flex !important;
    margin-left: 10px;
    align-items: center;
  }
  .col-left {
    display: none;
    width: 100% !important;
  }
  .col-right {
    width: 100% !important;
  }
  .cardbody-content .row-1 p {
    font-size: 16px;
  }
  .heroimg {
    height: 230px !important;
  }
  .golden-logo {
    width: 75px;
  }
  .ticketimg {
    height: 245px !important;
  }
  .cardbody-content.card-body {
    border: none;
    background: #341d3f;
  }
  .card-collapse.card {
    background: #341d3f;
    box-shadow: none;
  }
  .btn-primary-colapse {
    background: none !important;
    box-shadow: none !important;
  }
  .grad-border-bottom {
    border: 1px solid;
    border-image: linear-gradient(90deg, #952af4 0%, #f3a157 100%) 1;
    margin: 10px 0;
  }
  .btn-primary-colapse {
    padding: 15px 0;
  }

  /* .modal.fade:not(.in).bottom .modal-dialog {
      -webkit-transform: translate3d(0, 125%, 0);
      transform: translate3d(0, 125%, 0);
  } */
}

.badge-bid img {
  margin-right: 10px;
}

/* custom rc-slider  */
.rc-slider-rail {
  background: #4b3a53 !important;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%) !important;
  position: absolute;
  width: 100%;
  height: 4px;
}

.rc-slider-track {
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.rc-slider-handle {
  background: linear-gradient(
    90deg,
    #faa366 38.52%,
    #be57e5 108.15%
  ) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  width: 20.67px !important;
  height: 21px !important;
  margin-top: -8px !important;
}
.rc-slider-dot {
  width: 3px !important;
  height: 4px !important;
  background: linear-gradient(90deg, #faa366 21%, #be57e5 115%) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  border-radius: 0 !important;
  bottom: 0 !important;
}
.rc-slider-dot-active {
  background: #290f2d !important;
}

/* product card */

.productcard.card {
  border-radius: 9px;
  background: #2d1c35;
  border: none;
  margin-bottom: 30px;
}
.card-body-golden {
  background: #2d1c35 !important;
}

.goldencard.card {
  border-radius: 9px !important;
  background: linear-gradient(
    89.95deg,
    #8f13ff 0.01%,
    #f6a552 99.93%
  ) !important;
  border: none !important;
  margin-bottom: 30px;
}
.singleproductcard.card {
  width: 564px;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 30px;
}

.assetinfocard.card {
  background: #310a3d;
  border: none;
  width: 564px;
  border-radius: 9px !important;
  border: none !important;
  margin-bottom: 30px;
}

.heroimg {
  height: 275px;
  /* height: calc(100vw/6) ; */
  width: 100%;
  object-fit: contain;
}

.single-productcard {
  width: 564px;
  height: 687px;
}

.single-heroimg {
  width: 437px;
  height: 452px;
}

.cardbg-UNCOMMON {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
  padding: 45px 50px 45px 29px;
}
.cardbg-single {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 45px 50px 45px 29px;
}
.Artifacts-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  min-width: 115px;
}
.Asset-info {
  margin-bottom: 1px;
}
.Asset-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}

.buy-btn-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
  width: 50%;
  margin: 30px 24px 10px 0;
}

.buy-btn.btn {
  background: #36154a !important;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  width: 100%;
}
.bid-btn-grad {
  width: 50%;
}
.bid-btn.btn {
  width: 100%;
  margin: 30px 0 10px 0;
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
}
/* .transaction-table .t-header{
    border-radius: 9px 9px 0px 0px;
    background: #310A3D;
    box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2); 
  } */
.transaction-table thead tr th {
  background: #310a3d;
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}
.transaction-table tbody tr:nth-child(odd) {
  background: #331346;
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}
.transaction-table tbody tr {
  border-bottom: 0;
}
/* #root > div > div:nth-child(2) > div > div:nth-child(2) > div.table-card > div > table > tbody > tr:nth-child(3) > td:nth-child(1) */
.transaction-table tbody tr:nth-child(n) td:first-child {
  border-radius: 0 0 0 9px !important;
}
.transaction-table tbody tr:nth-child(n) td:last-child {
  border-radius: 0 0 9px 0 !important;
}

.transaction-table tbody tr:nth-child(even) {
  background-color: #36154a;
}

.transaction-table thead tr th:first-child {
  border-radius: 9px 0 0 0;
}

.transaction-table tbody tr td {
  padding: 20px 30px;
  color: #ffffff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
}
.transaction-table thead tr th:last-child {
  border-radius: 0 9px 0 0;
}

.bg-golden {
  background-image: url("http://localhost:3000/glow.svg");
  /* background: linear-gradient(89.95deg, #8F13FF 0.01%, #F6A552 99.93%); */
  mix-blend-mode: normal;
  border-radius: 9px 9px 0px 0px;
  position: relative;
  box-sizing: border-box;
  padding: 0px 50px 0px 29px;
}
.bg-COMMON {
  background: linear-gradient(180deg, #c6cacf 0%, #586574 100%);
}

.text-COMMON {
  color: #f6f0eb;
}

.bg-UNCOMMON {
  background: linear-gradient(180deg, #5ea056 0%, #1d342c 100%);
}
.text-UNCOMMON {
  color: #7dfb4b;
}
.bg-RARE {
  background: linear-gradient(180deg, #4176b8 0%, #272845 100%);
}
.text-RARE {
  color: #4faeff;
}
.bg-EPIC {
  background: linear-gradient(180deg, #8d1bbf 0%, #270a33 100%);
}
.text-EPIC {
  color: #c84fff;
}
.bg-LEGENDARY {
  background: linear-gradient(180deg, #dd8b40 0%, #47270a 100%);
}
.text-LEGENDARY {
  color: #ff8500;
}

.cardbg-UNCOMMON .name-logo,
.cardbg-single .name-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 50;
  width: auto;
  display: initial;
  height: auto;
}

.golden-logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}
.cardbg-UNCOMMON .energy-bage,
.cardbg-single .energy-bage {
  position: absolute;
  height: 30px;
  bottom: 20px;
  left: 20px;
  background: #37263f;
  border-radius: 9px;
  z-index: 50;
  display: flex;
  align-items: center;
}
.cardbg-UNCOMMON .energy-bage img,
.cardbg-single .energy-bage img {
  margin: -2px 10px 0 10px;
  width: 23px;
  height: 39px;
}
.cardbg-UNCOMMON .energy-bage span,
.cardbg-single .energy-bage span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-right: 10px;
}
.name-card .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  /* font-size: 25px; */
  line-height: 38px;
}
.name-card .rarity {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}
.name-card .level {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
}
.stats-card {
  background: #37263f;
  border-bottom: 1px solid !important;
  border-image: linear-gradient(90deg, #d1903c 0%, #7321c1 100%) 1 !important;
  border-top: 1px solid !important;
}
.statsbadge-info {
  margin-left: 10px;
  min-width: 75px;
}

.Artifacts-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.ownerid {
  margin-right: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.06em;
}
.single-stats-card {
  background: #2e1140;
  border-radius: 0 0 9px 9px;
  /* padding: 20px 30px !important; */
}

.single-price-card {
  background: #2e1140;
  padding: 20px 30px !important;
}
.b-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.stats-card .statsbadge {
  background: #2d1c35;
  border-radius: 0 5px 5px 0;
  padding: 3px 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  overflow-wrap: break-word;
  height: 100%;
}

.price-card .offer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
}
.price-card .bid {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
}
.price-card .time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 30px;
  letter-spacing: 0.01em;
  margin: 0 0 0 10px;
}
.powerbar {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 50;
}
.seemore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 103px 8px 100px;
  width: 323px;
  height: 60px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  margin: 50px 0 100px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  cursor: pointer;
}

.assattier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #781e87 0%, #69229c 100%);
  border-radius: 20px;
  width: 100%;
  padding: 30px;
  margin: 20px 0;
}
.tiercard {
  background: #300a3c !important;
  border-radius: 9px !important;
  border: 0 !important;
  margin-bottom: 15px;
  color: #ffffff !important;
}
.tier-header.card-header {
  text-align: center;
  background: #36104e;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 9px 9px 0px 0px;
}
/* {
    text-align: center;
    background: #36104E;
    box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-transform: uppercase;
    padding: 10px 30px !important;
    border-radius: 9px 9px 0px 0px;
  } */
.tier-card {
  background: #451463;
  border-radius: 0 0 9px 9px;
  padding: 5px !important;
}
.tier-info {
  padding: 5px 10px;
}
.tier-info h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
}
.tier-info p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  width: 100%;
}
.tier-info p span {
  font-weight: 700;
}

.tier-slider {
  width: 70%;
  padding: 15px;
}
.tier-slider h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 47px;
  text-transform: uppercase;
  text-align: center;
  margin: 0px 0 25px 0;
}
.progress-bar__ {
  width: 100%;
  position: relative;
}
.progress {
  height: auto !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(91.93deg, #421341 0%, #451e69 47.87%, #400c42 96.74%);
  border-radius: 8px;
}

.assetsprgress .progress-bar {
  /* width: 100%; */
  margin: 3px !important;
  background: linear-gradient(90.08deg, #f6a552 0.13%, #8f13ff 261.69%);
  border-radius: 6px;
  height: 28px;
}
.vesting-progress .progress-bar {
  /* width: 100%; */
  background: linear-gradient(90.08deg, #f6a552 0.13%, #8f13ff 261.69%);
  border-radius: 6px;
  height: 8px;
}
.min-bar-value {
  position: absolute;
  top: -5px;
  left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 47px;
}
.middle-bar-value {
  position: absolute;
  top: -5px;
  left: 50%;
  right: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 47px;
}
.max-bar-value {
  position: absolute;
  top: -5px;
  right: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 47px;
  text-align: right;
}

/* mui slider custom */

.MuiSlider-track {
  height: 6px;
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border: none !important;
}
.MuiSlider-thumb {
  background: linear-gradient(90deg, #faa366 38.52%, #be57e5 108.15%);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
}
.MuiSlider-thumb :hover {
  box-shadow: 0px 0px 0px 8px rgba(218, 233, 248, 0.15);
}
.MuiSlider-rail {
  background: #4b3a53 !important;
  box-shadow: 0px 10px 94px rgb(147 52 207 / 20%) !important;
}
.MuiSlider-mark {
  background: linear-gradient(90deg, #faa366 21%, #be57e5 115%) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
}
.MuiSlider-markActive {
  background: #290f2d !important;
}

/* modal style */
.close-btn {
  padding: 10px 0px 0 0;
  cursor: pointer;
}
.connected-modal {
  padding: 18px 33px;
  width: 368px !important;
  border-radius: 50px !important;
}
.connected-header h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: black;
}
.connected-header img {
  cursor: pointer;
}

.success-modal {
  width: 100%;
  background: #2d1c35;
  border-radius: 20px;
}

.success-border {
  background: linear-gradient(
    90.07deg,
    rgba(132, 34, 228, 0.3) 1.1%,
    rgba(251, 174, 60, 0.3) 99.95%
  );
  padding: 1px;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 20px;
}

.center-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  color: var(--bs-modal-color);
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  flex-direction: initial;
}

.connect-modal {
  width: 368px !important;
  background: white;
  border-color: #000000;
  border-width: 90px;
  border-radius: 50px !important;
  animation: animatebottom 0.4s !important;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 100%) !important;
  -ms-transform: translate(0, 100%) !important;
  -o-transform: translate(0, 100%) !important;
  transform: translate(0, 1px) !important;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}
.connect-text,
.success-text {
  text-align: center;
  color: black;
  margin-bottom: 20px;
}

.sucess-btn-grad {
  width: 100%;
  padding: 10px 20px;
}
.cancle-btn-grad {
  width: 100%;
  padding: 1px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(
      90.06deg,
      rgba(132, 34, 228, 0.3) 1.08%,
      rgba(251, 174, 60, 0.3) 99.95%
    );
  border-radius: 9px !important;
}

.sucess-btn.btn {
  width: 100%;
  padding: 18px 0;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
}

.cancle-btn.btn {
  width: 100%;
  padding: 18px 0;
  border-radius: 9px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: #413049;
}

.connect-text h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 30px;
}

.success-text h3 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 64px;
}

.connect-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.success-text p {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
}

.connect-btn-grad {
  border-radius: 100px !important;
  padding: 1px;
  /* width: 100%; */
  margin: 10px 31px;
}
.disconnect-btn-grad {
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  border-radius: 9px !important;
  padding: 1px;
}

.connect-btn.btn {
  background: rgb(251, 165, 98);
  border-color: linear-gradient(
    90deg,
    rgba(251, 165, 98, 1) 0%,
    rgba(181, 75, 247, 1) 100%
  );
  border-radius: 9px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 7px !important;
  letter-spacing: 0.01em !important;
  color: white !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .connect-btn span{
    margin: 0px 5px;
    border-radius: 20px;
  } */
.newuserlink {
  padding: 25px 0;
}

.newuserlink p {
  margin: 25px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: black;
}
.newuserlink p a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #136cfb !important;
}
.connect-id,
.viewpolygonlink {
  margin: 20px 0;
}
.connect-id p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #ffffff;
}
.connect-id img,
.viewpolygonlink img {
  margin: 0 10px;
}
.viewpolygonlink p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 113.3%;
  color: #ffffff;
}

/* checkbox custom */
.form-check-input:checked {
  background-color: #451463 !important;
  border-color: #ffffff !important;
}
.form-check-input:focus {
  border-color: #ffffff;
  outline: 0;
  /* box-shadow: 0px 0px 0px 8px rgb(25 118 210 / 16%); */
}

/* Defi page css */

.defi-dash-bg-grad {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 1px;
  border-radius: 9px !important;
}
.defi-dash-bg {
  background: #413049 !important;
  border-radius: 9px !important;
  padding: 26px 20px;
  cursor: pointer;
}

.defi-price-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #fcfcfc;
}
.defi-price-card h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
}
.private-sale-btn {
  background: linear-gradient(90deg, #952af4 0%, #f3a157 100%);
  padding: 0px 3px;
  border-radius: 5px !important;
  margin: 0 0 0 8px;
}
.Rectangle-defi-img {
  width: 51px;
  height: 51px;
}

.BGEM-price {
  width: 250px;
}
.greenstat-img {
  width: 100px;
}
.play_to_earn4 {
  width: 130px;
  margin: -35px 0;
}

.defi-cards-bg {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  padding: 20px;
}
.defi-card-header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #fcfcfc;
}

.defi-card-header img,
.swapdefi-coin img,
.swaper-coin img {
  width: auto;
}

.swapdefi-coin {
  margin: 20px 0;
  padding: 8px;
  background: linear-gradient(
      89.24deg,
      rgba(144, 44, 237, 0.08) 0.68%,
      rgba(246, 157, 77, 0.08) 99.4%
    ),
    #000000;
  border-radius: 9px;
}

.uper-coin-input {
  background: linear-gradient(
      89.24deg,
      rgba(144, 44, 237, 0.08) 0.68%,
      rgba(246, 157, 77, 0.08) 99.4%
    ),
    #000000;
  outline: none;
  padding: 0 10px;
  width: 125px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
  border: none;
}
.coinmax {
  padding: 1px 5px;
  background: #2d1c35;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.coin-drop-img1 {
  margin-right: 5px;
}
.coin-drop-img2 {
  margin-left: 5px;
}
.cursor-pointer {
  cursor: pointer;
}

.boom-img-coin {
  margin-right: 5px;
}
.swaper-coin {
  background: #000000;
  border: 4px solid #2d1c35;
  border-radius: 9px;
  padding: 8px;
  position: absolute;
  left: 45%;
}
.buy-btn-defi {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 9px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.remove-btn-defi {
  background: #413049;
  border-radius: 9px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.swaper-headding p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fcfcfc;
}
.swaper-headding img {
  width: 32px;
  margin: 0 3px;
}

.vesting-boom-img img {
  width: 231px;
}
.tab-content-defi {
  background: #413049;
  border-radius: 9px;
  padding: 1px;
}
.tabs-defi {
  border-radius: 9px;
  padding: 10px;
}
.tabs-defi-active {
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 9px;
  padding: 10px;
}
.defi-footer img {
  width: auto;
}
.defi-footer {
  /* position: absolute; */
  margin: 150px 0 0 0;
  bottom: 0;
}
.defi-footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.defi-footer-content a {
  cursor: pointer;
}

.defi-copright {
  padding: 10px 0;
  text-align: center;
  margin-top: 30px;
}
.defi-icons-margin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.defi-sale-private-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* game css */
.game-card-bg {
  background: linear-gradient(180deg, #2f1c38 0%, #563367 100%);
  border-radius: 30px;
}

.gamelefsideimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

.game-card-detail {
  margin: 50px;
}
.game-card-detail h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 47px;
  color: #ffffff;
}
.game-desc,
.gamebtn-content {
  margin-top: 50px;
}
.game-detail {
  min-width: 100px;
}
.game-detail span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #b3b3b3;
}
.game-detail p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.game-app-logo {
  width: 130px;
}

.game-play-btn {
  background: linear-gradient(89.95deg, #8f13ff 0.01%, #f6a552 99.93%);
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.gamebtn-content-border {
  margin-top: 50px;
  background: linear-gradient(90deg, #fbae3c 0%, #8f13ff 100%);
  padding: 1px;
  border-radius: 9px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.gamebtn-1 {
  background: #36154a;
  border-radius: 9px;
  padding: 10px;
  width: 100%;
}

/* governance css */
.gover-card-bg {
  background: linear-gradient(90deg, #800c93 0%, #7f2abe 100%);
  border-radius: 20px;
  padding: 45px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.governance-heading-card {
  width: 55%;
}
.governance-heading-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 100%;
  color: #ffffff;
}
.governance-stat-slider {
  width: 25%;
  text-align: center;
}
.governance-stat-slider p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 47px;
  text-transform: uppercase;
  color: #ffffff;
}
.governance-stat-slider span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.goverance-bar-value {
  position: absolute;
  top: -5px;
  left: 45%;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 47px;
}
.w-100 {
  width: 100%;
}
.gove-col-left {
  width: 25% !important;
}
.gove-col-right {
  width: 75% !important;
}
.gove-col-left img {
  width: auto;
}
.governance-sidebar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background: #2d1c35;
  box-shadow: 0px 10px 94px rgba(147, 52, 207, 0.2);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
.gov-table-header {
  border-radius: 9px 9px 0px 0px;
}

.governance-table thead tr th:first-child {
  border-radius: 9px 0 0 0;
}
.governance-table thead tr th:last-child {
  border-radius: 0 9px 0 0;
}

.governance-table tbody tr:nth-child(n) td:first-child {
  border-radius: 9px 0 0 9px !important;
}
.governance-table tbody tr:nth-child(n) td:last-child {
  border-radius: 0 9px 9px 0 !important;
}

.governance-table thead tr th {
  background: #2d1c35;
  padding: 20px 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff !important;
}
.governance-table tbody tr {
  margin-top: 20px;
  background: #2a0e37;
  border-radius: 8px;
}

.governance-table tbody tr td {
  padding: 20px 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff !important;
}

.gov-table-header {
  background: #2d1c35;
  border-radius: 9px 9px 0px 0px;
}
.expand-governance-table {
  padding: 20px;
  width: 100%;
  background: rgba(51, 29, 62, 0.6);
  border-radius: 0 0 9px 9px;
}

.Gov-Action-btn {
  background: #413049;
  border-radius: 9px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  margin-right: 20px;
}

.expanded-contet-box {
  padding: 20px;
}
.expanded-contet-box h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 10px;
}
.expanded-contet-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 30px;
}

/* Data table custom css */
.governace-data-table .rdt_Table {
  background: transparent;
}

.governace-data-table .rdt_TableHeadRow {
  background: #2d1c35;
  border-radius: 9px 9px 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.governace-data-table .rdt_TableRow {
  background: #2a0e37;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 20px;
  border: none;
}
.governace-data-table .rdt_TableRow svg {
  color: #ffffff;
}
.governace-data-table .rdt_ExpanderRow {
  background: rgba(51, 29, 62, 0.6);
  border-radius: 0 0 9px 9px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media screen and (max-width: 1135px) {
  .governance-heading-card,
  .governance-stat-slider {
    width: 100%;
    text-align: center;
  }
  .gove-col-right,
  .gove-col-left {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
