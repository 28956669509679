.grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  /* grid-template-rows: 400px 340px; */
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  width: 1200px;
  margin: auto;
  transform: scale(0.9);
}

.grid > *,
.golden-ticket-cards > * {
  border-radius: 30px;
  /* padding: 30px 20px; */
}

.golden-ticket-cards > * {
  padding: 30px;
}

.golden-ticket-cards p {
  margin-top: 20px;
}

.image {
  grid-row: 1/3;
  grid-column: 2/3;
  background-image: url(./goldenticket.png);
  background-size: cover;
}

.image img {
  display: none;
}

.golden-ticket-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.golden-ticket-cards ol {
  font-size: 18px;
  padding: 15px 15px 0;
}

.golden-ticket-cards > * {
  background: #2f1c38;
  grid-row: 1/2;
  grid-column: 1/2;
}

.golden-ticket-cards p {
  font-size: 18px;
}

.boomland-title {
  font-weight: bold;
  font-size: 62px;
  text-align: center;
  line-height: 60px;
}

/* .description {
}

.description-first-text {
    font-weight: bold;
    font-size: 25px;
    text-align: center;

}

.description p {
    font-size: 18px;
} */

.grid .heading {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.cards {
}

.time {
  background: #2f1c38;
  grid-row: 2/3;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.time-title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.hours {
  display: flex;
  gap: 20px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #8f13ff 0%, #f6a552 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hour {
  display: flex;
  flex-direction: column;
}

.desc {
  font-size: 16px;
  text-align: center;
  -webkit-text-fill-color: white;
}

.time-time-title,
.seperator {
  font-size: 65px;
  line-height: 80px;
  font-weight: bold;
}

.time-time-desc {
}

input.golden-ticket-email {
  background-color: #000000;
  color: white;
  text-align: center;
  border: none !important;
  outline: none !important;
  border-radius: 5px;
  font-size: 17px;
  width: 100%;
  padding: 10px;
  height: 50px;
}

input.golden-ticket-email::placeholder {
  color: rgb(255 255 255 / 70%);
}

input.golden-ticket-email:focus {
  box-shadow: 0 0 0 2px white;
}

input.golden-ticket-email.error:focus {
  box-shadow: 0 0 0 2px red;
}

button.primary,
button.sec {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  font-size: 18px;
  border: none;
  border-radius: 9px;
  color: white;
}

.time .buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.buttons span {
  font-size: 18px;
}

button.primary {
  background: linear-gradient(90deg, #8f13ff 0%, #f6a552 100%);
}

button.sec {
  background: linear-gradient(90deg, #8f13ff 0%, #f6a552 100%);
}

@media screen and (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr;
    /* grid-template-rows: 400px 400px 1200px; */
    grid-template-rows: auto auto auto;
    transform: scale(1);
    width: 80%;
    max-width: 600px;
  }
  .image {
    grid-row: 3/4;
    grid-column: 1/-1;
    background-image: initial;
    padding: 0;
  }

  .image img {
    display: block;
  }

  .grid .heading {
    font-size: 22px;
  }

  .boomland-title {
    font-size: 50px;
  }

  .description p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .time-time-title,
  .seperator {
    font-size: 10vw;
  }

  .description-title {
    font-size: 40px;
  }
}

@media screen and (max-width: 440px) {
  .grid {
    width: 90%;
  }
  .hours {
    gap: 15px;
  }

  .time .buttons {
    gap: 10px;
  }
}
