.banner {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  top: 100px;
  margin-bottom: 120px;
}

@media screen and (max-width: 995px) {
  .banner {
    top: 0px;
    margin-bottom: 0px;
  }
}

.banner-action-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  top: 50%;
  bottom: 50%;
  width: 45%;
}

.play-now-button {
  border-radius: 5px;
  border-width: 1px;
  border-color: white;
  min-width: 50%;
  background: linear-gradient(90deg, #b147ff 0%, #ffa95a 100%);
  margin-top: 4%;
  box-shadow: 1px 1px 1px 1px #0000;
}

.play-now-text {
  font-size: x-large;
  color: white;
  font-weight: bold;
}

.play-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6%;
}

@media screen and (max-width: 440px) {
  .play-now-text {
    font-size: x-small;
  }
}

@media screen and (min-width: 440px) and (max-width: 860px) {
  .play-now-text {
    font-size: medium;
  }
}

@media screen and (min-width: 860px) and (max-width: 1360px) {
  .play-now-text {
    font-size: large;
  }
}

@media screen and (min-width: 440px) and (max-width: 660px) {
  .banner {
    height: 40vh;
    width: 100vw;
  }

  .banner-action-container {
    width: 60%;
  }
}

@media screen and (max-width: 440px) {
  .banner-action-container {
    width: 50%;
  }
}
